import React from "react";
import "./Sections.css";
import BusinessNavbar from "../../../components/UI/Navbarbusiness/navbar";

const Index = () => {
  return (
    <div>
      <BusinessNavbar />
      Index
    </div>
  );
};

export default Index;
