import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import d1Img from "../../../../assets/download/02.png";
import d2Img from "../../../../assets/download/03.png";
import d3Img from "../../../../assets/download/04.png";
import d4Img from "../../../../assets/download/05.png";
import QRcode from "../../../../assets/download/Qrcode.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Download">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>Descarga la aplicación “TurkAfrica B2B”</h1>
          <p>
            Donde se encuentran las mentes empresariales más brillantes de
            África y Turquía.
          </p>
        </div>
        {/* <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div> */}
        <div className="download_img_main_div">
          <div className="div_of_image_box">
            <p>
              Crea un perfil profesional y atrae socios comerciales potenciales.
            </p>
            <div className="img_of_download">
              <img src={d1Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              ¡Obtén las últimas novedades sobre los lazos comerciales entre
              África y Turquía! Mantente informado.
            </p>
            <div className="img_of_download">
              <img src={d2Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>¡Conéctate y comunícate por voz, video o simplemente chatea!</p>
            <div className="img_of_download">
              <img src={d3Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>Videoconferencia con tus asociados y socios comerciales.</p>
            <div className="img_of_download">
              <img src={d4Img} alt="" />
            </div>
          </div>
        </div>
        <div className="store_div_download_section">
          <p>
            "¡Obtén la aplicación! Escanea el código QR para descargar ahora y
            experimentarlo instantáneamente!”
          </p>
          <div className="Qr_code_div">
            <img src={QRcode} alt="" />
          </div>
          <p>o</p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
