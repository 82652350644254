import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import d1Img from "../../../../assets/download/02.png";
import d2Img from "../../../../assets/download/03.png";
import d3Img from "../../../../assets/download/04.png";
import d4Img from "../../../../assets/download/05.png";
import QRcode from "../../../../assets/download/Qrcode.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Download">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>Téléchargez l'application “TurkAfrica B2B”</h1>
          <p>
            Là où les esprits d'affaires les plus brillants d'Afrique et de
            Turquie se rencontrent.
          </p>
        </div>
        {/* <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div> */}
        <div className="download_img_main_div">
          <div className="div_of_image_box">
            <p>
              Créez un profil professionnel et attirez des partenaires
              commerciaux potentiels.
            </p>
            <div className="img_of_download">
              <img src={d1Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Recevez les dernières nouvelles sur les liens commerciaux entre
              l'Afrique et la Turquie ! Restez informé.
            </p>
            <div className="img_of_download">
              <img src={d2Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Réseauter et communiquer via voix, vidéo, ou simplement discuter !
            </p>
            <div className="img_of_download">
              <img src={d3Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>Vidéoconférence avec vos associés et partenaires commerciaux.</p>
            <div className="img_of_download">
              <img src={d4Img} alt="" />
            </div>
          </div>
        </div>
        <div className="store_div_download_section">
          <p>
            "Obtenez l'application ! Scannez le code QR pour télécharger
            maintenant et l'expérimenter instantanément !”
          </p>
          <div className="Qr_code_div">
            <img src={QRcode} alt="" />
          </div>
          <p>ou</p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section8;
