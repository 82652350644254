import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import d1Img from "../../../../assets/download/02.png";
import d2Img from "../../../../assets/download/03.png";
import d3Img from "../../../../assets/download/04.png";
import d4Img from "../../../../assets/download/05.png";
import QRcode from "../../../../assets/download/Qrcode.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Download">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>Download The “TurkAfrica B2B App”</h1>
          <p>Where Africa and Türkiye's brightest business minds converge.</p>
        </div>
        {/* <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div> */}
        <div className="download_img_main_div">
          <div className="div_of_image_box">
            <p>
              Create a professional business profile and attract potential
              business partners.
            </p>
            <div className="img_of_download">
              <img src={d1Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Get the Latest on African Turkish business ties! Stye informed.
            </p>
            <div className="img_of_download">
              <img src={d2Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>Network and communicate via voice, video, or simply chat!</p>
            <div className="img_of_download">
              <img src={d3Img} alt="" />
            </div>
          </div>
          <div className="div_of_image_box">
            <p>
              Video conferencing with your associates and business partners.
            </p>
            <div className="img_of_download">
              <img src={d4Img} alt="" />
            </div>
          </div>
        </div>
        <div className="store_div_download_section">
          <p>
            "Get the App! Scan the QR Code to Download Now and Experience It
            Instantly!”
          </p>
          <div className="Qr_code_div">
            <img src={QRcode} alt="" />
          </div>
          <p>or</p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        {/* <div className="bottom_text_div">
          <p>
            Discover a comprehensive marketplace where you can acquire an
            extensive range of products essential for both your professional and
            daily needs. Engage with a diverse array of highly skilled
            professionals, each offering specialized services tailored to your
            requirements. Connect with an expansive network of over 1.5 billion
            consumers, and establish valuable relationships with a broad
            spectrum of business entities, including distributors,
            manufacturers, exporters, importers, wholesalers, retailers,
            licensors, licensees, and investors. <b>"TürkAfrica.Market" </b>
            stands as your gateway to a world of opportunities, fostering
            connections and growth in a dynamic, global marketplace.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Section8;
