import React from "react";
import "./Newsection.css";
import sec6img from "../../../../assets/Newsection.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";

const Index = () => {
  return (
    <div className="Newsection" id="Investors">
      <div className="submain_Newsection_div">
        <div className="Newsection_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection_text_div">
          <h1>
            Participation et Opportunités <br /> d'Investissement
          </h1>
          <p>
            Si vous êtes intéressé à explorer les opportunités de devenir un
            actionnaire valorisé ou souhaitez investir dans <b>TurkAfrica</b>,
            nous vous invitons chaleureusement à nous contacter. Pour plus de
            détails et pour entamer une conversation, veuillez vous rendre à la
            section 'Contact'. Nous attendons avec impatience l'opportunité de
            nous connecter avec vous.
          </p>
          <div className="Newsection_store_div">
            <p>Application Bientôt Disponible</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
