// import { useState, useContext, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";

// const Index = () => {
//   const [lang, setlang] = useState();
//   console.log("🚀 ~ Index ~ lang:", lang);
//   const pathname = useLocation();
//   const getname = pathname?.state?.pathname?.pathname;
//   useEffect(() => {
//     setlang(getname);
//   }, [pathname]);
//   const Navigation = useNavigate();
//   return (
//     <div className="main_div">
//       <div className="arrow">
//         <button
//           onClick={() =>
//             lang == "/Turkish"
//               ? Navigation("/Turkish")
//               : lang == "/French"
//               ? Navigation("/French")
//               : lang == "/Portuguese"
//               ? Navigation("/Portuguese")
//               : lang == "/Swahili"
//               ? Navigation("/Swahili")
//               : lang == "/Spanish"
//               ? Navigation("/Spanish")
//               : Navigation("/")
//           }
//           className="button-27"
//           role="button">
//           &#8592;
//         </button>
//       </div>
//       <h3 className={lang == "/French" ? "head_text_french" : "head_text"}>
//         {lang == "/Turkish"
//           ? "TürkAfrica.Market Gizlilik Politikası"
//           : lang == "/French"
//           ? "Politique de confidentialité et politique de cookies de TürkAfrica.Market"
//           : lang == "/Portuguese"
//           ? "Türkafrica.Market Política de privacidade e cookies "
//           : lang == "/Swahili"
//           ? "Sera ya Faragha ya TürkAfrica.Market"
//           : lang == "/Spanish"
//           ? "Política de privacidad y cookies de TürkAfrica.Market"
//           : "TürkAfrica.Market Privacy Policy & Cookies"}
//       </h3>
//       <div className="contacnt_div">
//         {lang == "/Turkish" ? (
//           <>
//             <div className="sub_title_heading">
//               <h6>Türkafrica.market Gizlilik Politikası</h6>
//               <h6>22 Aralık 2023 tarihinde güncellendi</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Giriş</h1>
//               <p>
//                 TürkAfrica.Market'a hoş geldiniz, güçlü iş bağlantıları
//                 geliştirmeye ve kapsamlı e-ticaret çözümleri sunmaya adanmış
//                 önde gelen bir B2B ve B2C platformu. Bu Gizlilik Politikası,
//                 kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı,
//                 koruduğumuzu ve paylaştığımızı açıklar.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Bilgi Toplama</h1>
//               <p>
//                 Platformumuzda kayıt olduğunuzda, sipariş verdiğinizde veya
//                 hizmetlerimizle etkileşimde bulunduğunuzda verdiğiniz kişisel
//                 bilgileri toplarız. Bu şunları içerir: <br />
//                 Kişisel Tanımlama Bilgileri: İsim, e-posta adresi, telefon
//                 numarası, iş detayları. <br />• İşlemsel Bilgi: Platformumuz
//                 aracılığıyla gerçekleştirdiğiniz işlemlerin ve siparişlerin
//                 detayları. <br />
//                 Kullanım Verileri: Web sitemizi ve hizmetlerimizi nasıl
//                 kullandığınıza dair bilgiler.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Veri Kullanımı</h1>
//               <p>
//                 Verileriniz şunlar için kullanılır: <br />
//                 Hizmetleri Sağlamak: İşlemleri işlemek ve müşteri desteği
//                 sağlamak.
//                 <br />
//                 Platformumuzu Geliştirmek: Kullanıcı deneyimini geliştirmek ve
//                 yeni özellikler geliştirmek. <br />
//                 Pazarlama: Sadece onayınızla tanıtım mesajları ve teklifler
//                 göndermek.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Veri Paylaşımı ve Açıklama</h1>
//               <p>
//                 Bilgilerinizi şunlarla paylaşabiliriz:
//                 <br />
//                 Hizmet Sağlayıcılar: İş operasyonlarında yardımcı olan ortaklar
//                 (örn. ödeme işleme, lojistik). <br />
//                 Yasal Yükümlülükler: Yasalar gerektirdiğinde veya haklarımızı
//                 korumak için.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Veri Güvenliği</h1>
//               <p>
//                 Verilerinizi yetkisiz erişim ve ihlallerden korumak için
//                 şifreleme ve erişim kontrolleri gibi sağlam güvenlik önlemleri
//                 uyguluyoruz.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>6. Veri Saklama</h1>
//               <p>
//                 Hizmetlerimizi sağlamak veya yasalar gerektirdiği sürece kişisel
//                 verilerinizi saklarız, ardından güvenli bir şekilde silinir veya
//                 anonimleştirilir.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>7. Kullanıcı Hakları</h1>
//               <p>
//                 UK GDPR altında, kişisel verilerinize erişme, düzeltme, silme
//                 veya kullanımını kısıtlama hakkına sahipsiniz. Bu haklarınızı
//                 kullanmak için bize başvurabilirsiniz.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>8. Uluslararası Veri Transferleri</h1>
//               <p>
//                 Verileri İngiltere dışına aktardığımızda, yeterli korumayı
//                 sağladığımızı ve yasal çerçevelere uyduğumuzu garanti ederiz.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>9. İletişim Bilgileri</h1>
//               <p>
//                 Gizlilikle ilgili her türlü sorunuz için, lütfen açılış
//                 sayfasındaki 'İletişim' bölümünden bize ulaşın.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>10. Politika Güncellemeleri</h1>
//               <p>
//                 Bu politikayı güncelleyebilir ve önemli değişiklikleri
//                 platformumuz üzerinden bildirebiliriz.
//               </p>
//             </div>
//             <div className="sub_title_heading">
//               <h6>TürkAfrica.Market Çerez Politikası</h6>
//               <h6>Updated on December 22, 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Giriş </h1>
//               <p>
//                 Çerezler, kullanıcı deneyimini geliştirmek için kullanılan küçük
//                 metin dosyalarıdır. Çerez Politikamız, platformumuzda
//                 kullanımlarını açıklar.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Kullanılan Çerez Türleri </h1>
//               <p>
//                 Temel Çerezler: Platformun işlevselliği için gerekli. <br />
//                 Analitik Çerezler: Hizmetlerimizi geliştirmek için kullanım
//                 verilerini toplar. <br />
//                 Pazarlama Çerezleri: Reklamları kişiselleştirmek için
//                 kullanılır.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Çerezleri Yönetme </h1>
//               <p>
//                 Çerez tercihlerinizi tarayıcı ayarlarınızdan yönetebilirsiniz.{" "}
//                 <br />
//                 Zorunlu olmayan çerezler için izin, platformumuzu ilk
//                 ziyaretinizde alınır.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Çerez Politikası Değişiklikleri</h1>
//               <p>
//                 Bu politikayı güncelleyebilir ve platformumuzda bildirimde
//                 bulunabiliriz.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. İletişim Bilgileri</h1>
//               <p>
//                 Çerez Politikamız hakkında herhangi bir sorunuz varsa, lütfen
//                 açılış sayfasındaki 'İletişim' bölümünden bize ulaşın.
//               </p>
//             </div>
//           </>
//         ) : lang == "/French" ? (
//           <>
//             <div className="sub_title_heading">
//               <h6>Politique de Confidentialité de TürkAfrica.Market</h6>
//               <h6>Mis à jour le 22 décembre 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introduction</h1>
//               <p>
//                 Bienvenue sur TürkAfrica.Market, une plateforme B2B et B2C de
//                 premier plan dédiée à renforcer les connexions commerciales et à
//                 fournir des solutions complètes de commerce électronique. Cette
//                 Politique de Confidentialité décrit comment nous collectons,
//                 utilisons, protégeons et partageons vos informations
//                 personnelles.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Collecte d'Informations </h1>
//               <p>
//                 Nous collectons des informations personnelles que vous
//                 fournissez lors de votre inscription sur notre plateforme, de
//                 vos commandes ou de vos interactions avec nos services. Cela
//                 inclut : <br />
//                 Informations Transactionnelles : Détails des transactions et
//                 commandes effectuées via notre plateforme.
//                 <br />
//                 Données d'Utilisation : Informations sur la façon dont vous
//                 utilisez notre site Web et nos services.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Utilisation des Données</h1>
//               <p>
//                 Vos données sont utilisées pour : <br />
//                 Fournir des Services : Traiter les transactions et fournir un
//                 support client. <br />
//                 Améliorer Notre Plateforme : Améliorer l'expérience utilisateur
//                 et développer de nouvelles fonctionnalités. <br />
//                 Marketing : Envoyer des messages promotionnels et des offres,
//                 uniquement avec votre consentement.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Partage et Divulgation des Données</h1>
//               <p>
//                 Nous pouvons partager vos informations avec : <br />
//                 Prestataires de Services : Partenaires qui assistent dans les
//                 opérations commerciales (par exemple, traitement des paiements,
//                 logistique). <br />
//                 Obligations Légales : Lorsque requis par la loi ou pour protéger
//                 nos droits.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Sécurité des Données</h1>
//               <p>
//                 Nous mettons en œuvre des mesures de sécurité robustes telles
//                 que le cryptage et les contrôles d'accès pour protéger vos
//                 données contre les accès non autorisés et les violations.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>6. Conservation des Données</h1>
//               <p>
//                 Nous conservons vos données personnelles aussi longtemps que
//                 nécessaire pour fournir nos services ou tel que requis par la
//                 loi, après quoi elles sont supprimées de manière sécurisée ou
//                 anonymisées.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>7. Droits de l'Utilisateur</h1>
//               <p>
//                 Sous le RGPD du Royaume-Uni, vous avez le droit d'accéder, de
//                 corriger, de supprimer ou de restreindre l'utilisation de vos
//                 données personnelles. Vous pouvez exercer ces droits en nous
//                 contactant.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>8. Transferts de Données Internationaux</h1>
//               <p>
//                 Si nous transférons des données hors du Royaume-Uni, nous
//                 assurons une protection adéquate et le respect des cadres
//                 juridiques.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>9. Informations de Contact</h1>
//               <p>
//                 Pour toute demande liée à la vie privée, veuillez nous contacter
//                 via la section "Contact" sur la page d'accueil.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>10. Mises à Jour de la Politique</h1>
//               <p>
//                 Nous pouvons mettre à jour cette politique et vous en
//                 informerons de tout changement significatif via notre
//                 plateforme.
//               </p>
//             </div>
//             <div className="sub_title_heading">
//               <h6>Politique de Cookies de TürkAfrica.Market</h6>
//               <h6>Mis à jour le 22 décembre 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introduction </h1>
//               <p>
//                 Les cookies sont de petits fichiers texte utilisés pour
//                 améliorer l'expérience utilisateur. Notre Politique de Cookies
//                 explique leur utilisation sur notre plateforme.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Types de Cookies Utilisés </h1>
//               <p>
//                 Cookies Essentiels : Nécessaires pour la fonctionnalité de la
//                 plateforme. <br />
//                 Cookies Analytiques : Collectent des données d'utilisation pour
//                 améliorer nos services.
//                 <br />
//                 Cookies Marketing : Utilisés pour personnaliser la publicité.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Gestion des Cookies </h1>
//               <p>
//                 Vous pouvez gérer vos préférences de cookies via les paramètres
//                 de votre navigateur. <br />
//                 Le consentement pour les cookies non essentiels est obtenu lors
//                 de votre première visite sur notre plateforme.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Modifications de la Politique de Cookies </h1>
//               <p>
//                 Nous pouvons mettre à jour cette politique et fournirons un avis
//                 sur notre plateforme.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Informations de Contact </h1>
//               <p>
//                 Pour toute question concernant notre Politique de Cookies,
//                 veuillez nous contacter via la section "Contact" sur la page
//                 d'accueil.
//               </p>
//             </div>
//           </>
//         ) : lang == "/Portuguese" ? (
//           <>
//             <div className="sub_title_heading">
//               <h6>Política de Privacidade da TürkAfrica.Market</h6>
//               <h6>Atualizado em 22 de dezembro de 2023.</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introdução</h1>
//               <p>
//                 Bem-vindo à TürkAfrica.Market, uma plataforma líder B2B e B2C
//                 dedicada a promover fortes conexões comerciais e fornecer
//                 soluções abrangentes de comércio eletrônico. Esta Política de
//                 Privacidade descreve como coletamos, usamos, protegemos e
//                 compartilhamos suas informações pessoais.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Coleta de Informações</h1>
//               <p>
//                 Coletamos informações pessoais que você fornece ao se registrar
//                 em nossa plataforma, fazer pedidos ou interagir com nossos
//                 serviços. Isso inclui: <br />
//                 Informações de Identificação Pessoal: Nome, endereço de e-mail,
//                 número de telefone, detalhes comerciais. Informações
//                 Transacionais: Detalhes das transações e pedidos realizados
//                 através de nossa plataforma.
//                 <br />
//                 Dados de Uso: Informações sobre como você usa nosso site e
//                 serviços.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Uso de Dados</h1>
//               <p>
//                 Seus dados são usados para: <br />
//                 Cumprir Serviços: Processar transações e fornecer suporte ao
//                 cliente. <br />
//                 Melhorar Nossa Plataforma: Aprimorar a experiência do usuário e
//                 desenvolver novos recursos. <br />
//                 Marketing: Enviar mensagens promocionais e ofertas, somente com
//                 o seu consentimento.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Compartilhamento e Divulgação de Dados</h1>
//               <p>
//                 Podemos compartilhar suas informações com: <br />
//                 Prestadores de Serviços: Parceiros que auxiliam nas operações
//                 comerciais (por exemplo, processamento de pagamentos,
//                 logística). <br />
//                 Obrigações Legais: Quando exigido por lei ou para proteger
//                 nossos direitos.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Segurança de Dados</h1>
//               <p>
//                 Implementamos medidas de segurança robustas, como criptografia e
//                 controles de acesso, para proteger seus dados de acessos não
//                 autorizados e violações.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>6. Retenção de Dados</h1>
//               <p>
//                 Retemos seus dados pessoais pelo tempo necessário para fornecer
//                 nossos serviços ou conforme exigido por lei, após o qual são
//                 excluídos ou anonimizados de forma segura.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>7. Direitos do Usuário</h1>
//               <p>
//                 Sob o GDPR do Reino Unido, você tem o direito de acessar,
//                 corrigir, excluir ou restringir o uso de seus dados pessoais.
//                 Você pode exercer esses direitos entrando em contato conosco.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>8. Transferências Internacionais de Dados</h1>
//               <p>
//                 Se transferirmos dados para fora do Reino Unido, garantimos
//                 proteção adequada e conformidade com frameworks legais.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>9.Informações de Contato</h1>
//               <p>
//                 Para quaisquer perguntas relacionadas à privacidade, por favor,
//                 entre em contato conosco através da seção "Contato" na página de
//                 destino.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>10. Atualizações da Política</h1>
//               <p>
//                 Podemos atualizar esta política e notificaremos você sobre
//                 mudanças significativas através de nossa plataforma.
//               </p>
//             </div>
//             <div className="sub_title_heading">
//               <h6>Política de Cookies da TürkAfrica.Market</h6>
//               <h6>Atualizado em 22 de dezembro de 2023.</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introdução </h1>
//               <p>
//                 Cookies são pequenos arquivos de texto usados para aprimorar a
//                 experiência do usuário. Nossa Política de Cookies explica o uso
//                 deles em nossa plataforma.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Tipos de Cookies Usados </h1>
//               <p>
//                 Cookies Essenciais: Necessários para a funcionalidade da
//                 plataforma. <br />
//                 Cookies de Análise: Coletam dados de uso para melhorar nossos
//                 serviços. <br />
//                 Cookies de Marketing: Usados para personalizar a publicidade.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Gerenciamento de Cookies </h1>
//               <p>
//                 Você pode gerenciar suas preferências de cookies através das
//                 configurações do seu navegador. <br />O consentimento para
//                 cookies não essenciais é obtido em sua primeira visita à nossa
//                 plataforma.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Alterações na Política de Cookies</h1>
//               <p>
//                 Podemos atualizar esta política e forneceremos aviso em nossa
//                 plataforma.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Informações de Contato </h1>
//               <p>
//                 Para quaisquer dúvidas sobre nossa Política de Cookies, por
//                 favor, entre em contato conosco através da seção "Contato" na
//                 página de destino.
//               </p>
//             </div>
//           </>
//         ) : lang == "/Swahili" ? (
//           <>
//             <div className="sub_title_heading">
//               <h6>TürkAfrica.Market Privacy Policy </h6>
//               <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Utangulizi</h1>
//               <p>
//                 Karibu kwenye TürkAfrica.Market, jukwaa la kipekee la B2B na B2C
//                 lililojitolea kuimarisha uhusiano wa kibiashara na kutoa
//                 suluhisho kamili la biashara ya mtandao. Sera hii ya Faragha
//                 inaelezea jinsi tunavyokusanya, kutumia, kulinda, na kushiriki
//                 taarifa yako binafsi.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Ukusanyaji wa Taarifa</h1>
//               <p>
//                 Tunakusanya taarifa binafsi unazotoa unapojisajili kwenye jukwaa
//                 letu, kuweka maagizo, au kuingiliana na huduma zetu. Hii
//                 inajumuisha: <br />
//                 Taarifa za Utambulisho Binafsi: Jina, anwani ya barua pepe,
//                 nambari ya simu, maelezo ya biashara.Taarifa za Miamala: Maelezo
//                 ya miamala na maagizo unayofanya kupitia jukwaa letu. <br />
//                 Data ya Matumizi: Taarifa kuhusu jinsi unavyotumia tovuti yetu
//                 na huduma
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Matumizi ya Data</h1>
//               <p>
//                 Data yako inatumika kwa: <br />
//                 Kutimiza Huduma: Kuchakata miamala na kutoa usaidizi kwa wateja.{" "}
//                 <br />
//                 Kuboresha Jukwaa Letu: Kuimarisha uzoefu wa mtumiaji na kukuza
//                 vipengele vipya. <br />
//                 Masoko: Kutuma ujumbe wa kibiashara na ofa, tu kwa idhini yako
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Ushirikiano na Ufunuo wa Data</h1>
//               <p>
//                 Tunaweza kushiriki taarifa yako na: <br />
//                 Watoa Huduma: Washirika wanaosaidia katika operesheni za
//                 biashara (k.m. uchakataji wa malipo, vifaa vya usafirishaji).{" "}
//                 <br />
//                 Majukumu ya Kisheria: Tunapohitajika kisheria au kulinda haki
//                 zetu.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Usalama wa Data</h1>
//               <p>
//                 Tunatekeleza hatua madhubuti za usalama kama usimbaji fiche na
//                 udhibiti wa ufikiaji kulinda data yako dhidi ya ufikiaji
//                 usioruhusiwa na uvunjaji.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>6. Uhifadhi wa Data</h1>
//               <p>
//                 Tunahifadhi data yako binafsi kwa muda mrefu kama inavyohitajika
//                 kutoa huduma zetu au kama inavyotakiwa na sheria, baada ya hapo
//                 inafutwa kwa usalama au kufanywa isiyoweza kutambulika.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>7. Haki za Mtumiaji</h1>
//               <p>
//                 Chini ya GDPR ya Uingereza, una haki ya kufikia, kusahihisha,
//                 kufuta, au kuzuia matumizi ya data yako binafsi. Unaweza
//                 kutekeleza haki hizi kwa kuwasiliana nasi.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>8. Uhamishaji wa Data wa Kimataifa</h1>
//               <p>
//                 IIkiwa tunahamisha data nje ya Uingereza, tunahakikisha ulinzi
//                 wa kutosha na kufuata mifumo ya kisheria.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>9. Taarifa za Mawasiliano</h1>
//               <p>
//                 Kwa uchunguzi wowote unaohusiana na faragha, tafadhali wasiliana
//                 nasi kupitia sehemu ya "Mawasiliano" kwenye ukurasa wa kutua.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>10. Maboresho ya Sera</h1>
//               <p>
//                 Tunaweza kuboresha sera hii na tutakujulisha kuhusu mabadiliko
//                 makubwa kupitia jukwaa letu.
//               </p>
//             </div>
//             <div className="sub_title_heading">
//               <h6>Sera ya Kuki ya TürkAfrica.Market</h6>
//               <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Utangulizi </h1>
//               <p>
//                 Kuki ni faili ndogo za maandishi zinazotumika kuboresha uzoefu
//                 wa mtumiaji. Sera yetu ya Kuki inafafanua matumizi yao kwenye
//                 jukwaa letu.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Aina za Kuki Zinazotumika </h1>
//               <p>
//                 Kuki Muhimu: Zinahitajika kwa utendakazi wa jukwaa.
//                 <br />
//                 Kuki za Takwimu: Zinakusanya data ya matumizi kuboresha huduma
//                 zetu.
//                 <br />
//                 Kuki za Matangazo: Zinatumika kwa ajili ya kubinafsisha
//                 matangazo.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Usimamizi wa Kuki </h1>
//               <p>
//                 Unaweza kudhibiti mapendeleo yako ya kuki kupitia mipangilio ya
//                 kivinjari chako. Idhini kwa kuki zisizo muhimu inapatikana
//                 unapotembelea jukwaa letu kwa mara ya kwanza.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Mabadiliko kwenye Sera ya Kuki</h1>
//               <p>
//                 Tunaweza kuboresha sera hii na tutatoa taarifa kwenye jukwaa
//                 letu.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5.Taarifa za Mawasiliano </h1>
//               <p>
//                 Kwa maswali yoyote kuhusu Sera yetu ya Kuki, tafadhali wasiliana
//                 nasi kupitia sehemu ya "Mawasiliano" kwenye ukurasa wa kutua.
//               </p>
//             </div>
//           </>
//         ) : lang == "/Spanish" ? (
//           <>
//             <div className="sub_title_heading">
//               <h6>Política de Privacidad de TürkAfrica.Market</h6>
//               <h6>Actualizado el 22 de diciembre de 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introducción</h1>
//               <p>
//                 Bienvenido a TürkAfrica.Market, una plataforma líder B2B y B2C
//                 dedicada a fomentar fuertes conexiones comerciales y
//                 proporcionar soluciones integrales de comercio electrónico. Esta
//                 Política de Privacidad describe cómo recopilamos, usamos,
//                 protegemos y compartimos su información personal.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Recolección de Información</h1>
//               <p>
//                 Recopilamos información personal que usted proporciona al
//                 registrarse en nuestra plataforma, realizar pedidos o
//                 interactuar con nuestros servicios. Esto incluye: <br />
//                 Información de Identificación Personal: Nombre, dirección de
//                 correo electrónico, número de teléfono, detalles comerciales.
//                 Información Transaccional: Detalles de las transacciones y
//                 pedidos que realiza a través de nuestra plataforma. <br />
//                 Datos de Uso: Información sobre cómo usa nuestro sitio web y
//                 servicios.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Uso de Datos</h1>
//               <p>
//                 Sus datos se utilizan para:
//                 <br />
//                 Cumplir Servicios: Procesar transacciones y proporcionar soporte
//                 al cliente. <br />
//                 Mejorar Nuestra Plataforma: Mejorar la experiencia del usuario y
//                 desarrollar nuevas funciones.
//                 <br />
//                 Marketing: Enviar mensajes promocionales y ofertas, solo con su
//                 consentimiento.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Compartición y Divulgación de Datos</h1>
//               <p>
//                 Podemos compartir su información con: <br />
//                 Proveedores de Servicios: Socios que ayudan en las operaciones
//                 comerciales (por ejemplo, procesamiento de pagos, logística).{" "}
//                 <br />
//                 Obligaciones Legales: Cuando lo exija la ley o para proteger
//                 nuestros derechos.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Seguridad de Datos</h1>
//               <p>
//                 Implementamos medidas de seguridad robustas como encriptación y
//                 controles de acceso para proteger sus datos del acceso no
//                 autorizado y violaciones.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>6. Retención de Datos</h1>
//               <p>
//                 Retenemos sus datos personales el tiempo que sea necesario para
//                 proporcionar nuestros servicios o según lo requiera la ley,
//                 después de lo cual se elimina de manera segura o se anonimiza.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>7. Derechos del Usuario</h1>
//               <p>
//                 Bajo el GDPR del Reino Unido, tiene derecho a acceder, corregir,
//                 eliminar o restringir el uso de sus datos personales. Puede
//                 ejercer estos derechos contactándonos.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>8. Transferencias Internacionales de Datos</h1>
//               <p>
//                 Si transferimos datos fuera del Reino Unido, aseguramos una
//                 protección adecuada y cumplimiento con marcos legales.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>9. Información de Contacto </h1>
//               <p>
//                 Para cualquier consulta relacionada con la privacidad, por favor
//                 contáctenos a través de la sección "Contacto" en la página de
//                 aterrizaje.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>10. Actualizaciones de la Política</h1>
//               <p>
//                 Podemos actualizar esta política y le notificaremos sobre
//                 cambios significativos a través de nuestra plataforma.
//               </p>
//             </div>
//             <div className="sub_title_heading">
//               <h6>Política de Cookies de TürkAfrica.Market</h6>
//               <h6>Actualizado el 22 de diciembre de 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introducción </h1>
//               <p>
//                 Las cookies son pequeños archivos de texto utilizados para
//                 mejorar la experiencia del usuario. Nuestra Política de Cookies
//                 explica su uso en nuestra plataforma.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Tipos de Cookies Utilizadas </h1>
//               <p>
//                 Cookies Esenciales: Necesarias para la funcionalidad de la
//                 plataforma. <br />
//                 Cookies de Análisis: Recopilan datos de uso para mejorar
//                 nuestros servicios. <br />
//                 Cookies de Marketing: Utilizadas para personalizar la
//                 publicidad.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Gestión de Cookies </h1>
//               <p>
//                 Puede gestionar sus preferencias de cookies a través de la
//                 configuración de su navegador. <br />
//                 El consentimiento para cookies no esenciales se obtiene en su
//                 primera visita a nuestra plataforma.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Cambios en la Política de Cookies</h1>
//               <p>
//                 Podemos actualizar esta política y proporcionaremos aviso en
//                 nuestra plataforma.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Información de Contacto</h1>
//               <p>
//                 Para cualquier consulta sobre nuestra Política de Cookies, por
//                 favor contáctenos a través de la sección "Contacto" en la página
//                 de aterrizaje.
//               </p>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="sub_title_heading">
//               <h6>TürkAfrica.Market Privacy Policy </h6>
//               <h6>Updated on December 22, 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introduction</h1>
//               <p>
//                 Welcome to TürkAfrica.Market, a leading B2B and B2C platform
//                 dedicated to fostering strong business connections and providing
//                 comprehensive e-commerce solutions. This Privacy Policy outlines
//                 how we collect, use, protect, and share your personal
//                 information.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Information Collection</h1>
//               <p>
//                 We collect personal information that you provide when you
//                 register on our platform, place orders, or interact with our
//                 services. This includes: <br />
//                 Personal Identification Information: Name, email address, phone
//                 number, business details. Transactional Information: Details of
//                 the transactions and orders you carry out through our platform.{" "}
//                 <br />
//                 Usage Data: Information on how you use our website and services.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Use of Data</h1>
//               <p>
//                 Your data is used to: Fulfill Services: <br />
//                 Process transactions and provide customer support. <br />
//                 Improve Our Platform: Enhance user experience and develop new
//                 features. <br />
//                 Marketing: Send promotional messages and offers, only with your
//                 consent.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Data Sharing and Disclosure</h1>
//               <p>
//                 We may share your information with: <br />
//                 Service Providers: Partners who assist in business operations
//                 (e.g., payment processing, logistics). <br />
//                 Legal Obligations: When required by law or to protect our
//                 rights.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Data Security</h1>
//               <p>
//                 We implement robust security measures like encryption and access
//                 controls to protect your data from unauthorized access and
//                 breaches.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>6. Data Retention</h1>
//               <p>
//                 We retain your personal data as long as necessary to provide our
//                 services or as required by law, after which it is securely
//                 deleted or anonymized.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>7. User Rights</h1>
//               <p>
//                 Under the UK GDPR, you have the right to access, correct,
//                 delete, or restrict the use of your personal data. You can
//                 exercise these rights by contacting us.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>8. International Data Transfers</h1>
//               <p>
//                 If we transfer data outside the UK, we ensure adequate
//                 protection and compliance with legal frameworks.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>9. Contact Information</h1>
//               <p>
//                 For any privacy-related inquiries, please contact us through the
//                 'Contact' section on the landing page
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>10. Policy Updates</h1>
//               <p>
//                 We may update this policy and will notify you of significant
//                 changes through our platform.
//               </p>
//             </div>
//             <div className="sub_title_heading">
//               <h6>TürkAfrica.Market Cookies</h6>
//               <h6>Updated on December 22, 2023</h6>
//             </div>
//             <div className="text_div">
//               <h1>1. Introduction </h1>
//               <p>
//                 Cookies are small text files used to enhance user experience.
//                 Our Cookie Policy explains their use on our platform.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>2. Types of Cookies Used </h1>
//               <p>
//                 Essential Cookies: Necessary for the platform's functionality.{" "}
//                 <br />
//                 Analytics Cookies: Collect usage data to improve our services.{" "}
//                 <br />
//                 Marketing Cookies: Used for personalizing advertising.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>3. Managing Cookies </h1>
//               <p>
//                 You can manage your cookie preferences through your browser
//                 settings. <br />
//                 Consent for nonessential cookies is obtained upon your first
//                 visit to our platform.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>4. Changes to Cookie Policy </h1>
//               <p>
//                 We may update this policy and will provide notice on our
//                 platform.
//               </p>
//             </div>
//             <div className="text_div">
//               <h1>5. Contact Information </h1>
//               <p>
//                 For any queries about our Cookie Policy, please contact us
//                 through the 'Contact' section on the landing page..
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Index;
import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const [lang, setLang] = useState();
  const pathname = useLocation();
  const getname = pathname?.state?.pathname?.pathname;

  useEffect(() => {
    setLang(getname);
  }, [pathname]);

  const Navigation = useNavigate();

  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
              ? Navigation("/French")
              : lang == "/Portuguese"
              ? Navigation("/Portuguese")
              : lang == "/Swahili"
              ? Navigation("/Swahili")
              : lang == "/Spanish"
              ? Navigation("/Spanish")
              : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>

      <h3 className={lang == "/French" ? "head_text_french" : "head_text"}>
        {lang == "/Turkish"
          ? "TürkAfrica B2B Uygulaması Gizlilik Politikası ve Çerezler"
          : lang == "/French"
          ? "Politique de confidentialité et cookies de l'application TurkAfrica B2B"
          : lang == "/Portuguese"
          ? "Política de Privacidade e Cookies do App TurkAfrica B2B"
          : lang == "/Swahili"
          ? "Sera ya Faragha na Vidakuzi ya App ya TurkAfrica B2B"
          : lang == "/Spanish"
          ? "Política de privacidad y cookies de la aplicación TurkAfrica B2B"
          : "TurkAfrica B2B App Privacy Policy & Cookies"}
      </h3>

      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>TürkAfrica B2B Uygulaması Gizlilik Politikası</h6>
              <h6>Son Güncelleme: 18 Eylül 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica B2B Uygulamasına hoş geldiniz, Afrika ve Türkiye
                arasında güçlü iş bağlantıları kurmayı amaçlayan önde gelen
                işten işe (B2B) platform. Bu Gizlilik Politikası, hizmetlerimizi
                kullandığınızda kişisel bilgilerinizi nasıl topladığımızı,
                kullandığımızı, koruduğumuzu ve paylaştığımızı açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Topladığımız Bilgiler</h1>
              <p>
                Platformumuza kaydolduğunuzda, sipariş verdiğinizde veya
                hizmetlerimizle etkileşim kurduğunuzda şu bilgileri toplarız:
                <br />• Kişisel Kimlik Bilgileri: Adınız, e-posta adresiniz,
                telefon numaranız ve iş bilgileri.
                <br />• İşlemsel Bilgiler: Platformumuzda yapılan işlemler ve
                siparişlerin detayları.
                <br />• Kullanım Verileri: Web sitemizi ve hizmetlerimizi nasıl
                kullandığınıza dair bilgiler.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Verilerinizi Nasıl Kullanıyoruz?</h1>
              <p>
                Kişisel verilerinizi şunlar için kullanıyoruz:
                <br />• Hizmetlerimizi Sağlamak: İşlemleri işlemek, siparişleri
                tamamlamak ve müşteri desteği sunmak.
                <br />• Platformumuzu Geliştirmek: Kullanıcı davranışlarını
                analiz etmek ve yeni özellikler geliştirmek.
                <br />• Pazarlama (Onayla): Yalnızca onay verdiyseniz promosyon
                mesajları göndermek.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Verilerin Paylaşılması ve İfşa Edilmesi</h1>
              <p>
                Bilgilerinizi şu kişilerle paylaşabiliriz:
                <br />• Hizmet Sağlayıcılar: İş operasyonlarımıza yardımcı olan
                iş ortakları (ör. ödeme işlemcileri, lojistik hizmetleri).
                <br />• Yasal Uyum: Kanunen gerektiğinde veya yasal haklarımızı
                korumak için.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Veri Güvenliği</h1>
              <p>
                Kişisel bilgilerinizi yetkisiz erişim, kayıp veya ihlallere
                karşı korumak için endüstri standardı güvenlik önlemleri (ör.
                şifreleme) uyguluyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Veri Saklama</h1>
              <p>
                Verilerinizi, hizmetlerimizi sunmak için gereken süre boyunca
                veya yasaların gerektirdiği süre boyunca saklarız. Artık gerekli
                olmadığında, verileriniz güvenli bir şekilde silinir veya
                anonimleştirilir.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Haklarınız</h1>
              <p>
                UK Genel Veri Koruma Yönetmeliği (GDPR) kapsamında, kişisel
                verilerinize erişme, düzeltme, silme veya verilerinizin
                işlenmesini sınırlama hakkınız vardır. Bu haklarınızı kullanmak
                için lütfen bizimle iletişime geçin.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uluslararası Veri Aktarımları</h1>
              <p>
                Kişisel bilgilerinizi İngiltere dışına aktardığımızda, geçerli
                yasal çerçevelere uygun olarak koruma sağlıyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>9. İletişim Bilgileri</h1>
              <p>
                Gizlilikle ilgili herhangi bir soru için, lütfen açılış
                sayfamızdaki ‘İletişim’ bölümünden bize ulaşın.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Politika Güncellemeleri</h1>
              <p>
                Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Önemli
                değişiklikler yapıldığında, platformumuz üzerinden size bildirim
                göndereceğiz. Lütfen güncellemelerden haberdar olmak için
                periyodik olarak kontrol edin.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>TürkAfrica B2B Uygulaması Çerez Politikası</h6>
              <h6>Son Güncelleme: 18 Eylül 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş </h1>
              <p>
                Çerezler, kullanıcı deneyimini geliştirmek için cihazınızda
                saklanan küçük metin dosyalarıdır. Bu Çerez Politikası,
                TürkAfrica B2B Uygulaması platformunda çerezleri nasıl ve neden
                kullandığımızı açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kullandığımız Çerez Türleri </h1>
              <p>
                Temel Çerezler: Platformun ana işlevsellikleri için gereklidir.
                <br />
                Analitik Çerezler: Platformumuzu nasıl kullandığınızı anlamak
                için kullanılır.
                <br />
                Pazarlama Çerezleri: Tercihlerinize ve davranışlarınıza göre
                reklamları ve promosyon mesajlarını kişiselleştirir.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Çerezlerin Yönetimi</h1>
              <p>
                Çerezleri tarayıcı ayarlarınızdan yönetebilir veya
                engelleyebilirsiniz. Zorunlu olmayan çerezler için ilk ziyaret
                ettiğinizde onay istenir ve istediğiniz zaman onayınızı geri
                çekebilirsiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Çerez Politikasındaki Değişiklikler</h1>
              <p>
                Bu Çerez Politikasını zaman zaman güncelleyebiliriz. Önemli
                değişiklikler yapıldığında platformumuz üzerinden size bildirim
                göndereceğiz.
              </p>
            </div>
            <div className="text_div">
              <h1>5. İletişim Bilgileri</h1>
              <p>
                Çerez Politikası ile ilgili herhangi bir sorunuz varsa, lütfen
                açılış sayfamızdaki ‘İletişim’ bölümünden bize ulaşın.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>
                Politique de Confidentialité de l'application TurkAfrica B2B
              </h6>
              <h6>Dernière mise à jour : 18 septembre 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Bienvenue sur l'application TurkAfrica B2B, une plateforme
                dédiée à renforcer les connexions commerciales entre l'Afrique
                et la Turquie. Cette Politique de Confidentialité explique
                comment nous collectons, utilisons, protégeons et partageons vos
                informations personnelles lorsque vous utilisez nos services.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Informations que nous collectons</h1>
              <p>
                Nous collectons les informations personnelles suivantes lorsque
                vous vous inscrivez sur notre plateforme, passez des commandes
                ou interagissez avec nos services :
                <br />• Informations d'Identification Personnelle : Inclut votre
                nom, votre adresse e-mail, votre numéro de téléphone et vos
                informations professionnelles.
                <br />• Informations transactionnelles : Détails des
                transactions et commandes effectuées via notre plateforme.
                <br />• Données d'utilisation : Informations sur la façon dont
                vous naviguez et utilisez notre site Web et nos services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Comment nous utilisons vos données</h1>
              <p>
                Nous utilisons vos données personnelles pour :
                <br />• Fournir nos services : Traiter les transactions,
                exécuter les commandes et offrir un support client.
                <br />• Améliorer notre plateforme : Analyser le comportement
                des utilisateurs pour améliorer la plateforme et développer de
                nouvelles fonctionnalités.
                <br />• Marketing (avec consentement) : Envoyer des messages
                promotionnels et des offres spéciales, mais uniquement si vous y
                avez consenti.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Partage et divulgation des données</h1>
              <p>
                Nous pouvons partager vos informations avec :
                <br />• Fournisseurs de services : Partenaires qui nous aident à
                gérer nos opérations commerciales (par ex. processeurs de
                paiements, services logistiques).
                <br />• Conformité légale : Lorsque la loi l'exige ou pour
                protéger nos droits légaux.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Sécurité des données</h1>
              <p>
                Nous prenons la sécurité au sérieux et mettons en œuvre des
                mesures de sécurité conformes aux normes de l'industrie, telles
                que le chiffrement et les contrôles d'accès, pour protéger vos
                informations personnelles contre tout accès non autorisé, perte
                ou violation.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Conservation des données</h1>
              <p>
                Nous conservons vos informations personnelles aussi longtemps
                qu'elles sont nécessaires pour fournir nos services ou comme
                l'exige la loi. Une fois que les données ne sont plus
                nécessaires, elles sont supprimées ou anonymisées de manière
                sécurisée.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Vos droits</h1>
              <p>
                En vertu du Règlement général sur la protection des données
                (RGPD) du Royaume-Uni, vous avez le droit :
                <br />• D'accéder à vos données personnelles.
                <br />• De corriger les données inexactes ou incomplètes.
                <br />• De demander la suppression de vos données.
                <br />• De restreindre la manière dont nous traitons vos
                données.
                <br />
                Pour exercer ces droits, veuillez nous contacter en utilisant
                les informations fournies dans la section 'Contact' ci-dessous.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferts internationaux de données</h1>
              <p>
                Si vos informations personnelles sont transférées en dehors du
                Royaume-Uni, nous nous assurons qu'elles sont protégées en
                respectant les cadres juridiques applicables, y compris les
                clauses contractuelles types ou des mesures équivalentes.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Informations de contact</h1>
              <p>
                Pour toute question relative à la confidentialité, ou pour
                exercer vos droits en vertu de cette politique, veuillez nous
                contacter via la section 'Contact' disponible sur notre page
                d'accueil.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Mises à jour de la politique</h1>
              <p>
                Nous pouvons mettre à jour cette Politique de Confidentialité de
                temps en temps. Si des modifications importantes sont apportées,
                nous vous en informerons via notre plateforme ou par e-mail.
                Veuillez consulter régulièrement cette page pour rester informé
                des mises à jour.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Politique de Cookies de l'application TurkAfrica B2B</h6>
              <h6>Dernière mise à jour : 18 septembre 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Les cookies sont de petits fichiers texte stockés sur votre
                appareil pour améliorer l'expérience utilisateur. Cette
                Politique de Cookies explique comment et pourquoi nous utilisons
                des cookies sur la plateforme TurkAfrica B2B.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types de cookies que nous utilisons</h1>
              <p>
                Cookies essentiels : Ces cookies sont nécessaires aux
                fonctionnalités de base de la plateforme, telles que les
                connexions sécurisées et le traitement des transactions.
                <br />
                Cookies analytiques : Utilisés pour collecter des données sur la
                manière dont les utilisateurs interagissent avec notre
                plateforme, nous aidant à améliorer nos services.
                <br />
                Cookies marketing : Nous aident à personnaliser la publicité et
                les messages promotionnels en fonction de vos préférences et de
                votre comportement.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestion de vos cookies</h1>
              <p>
                Vous pouvez gérer ou bloquer les cookies via les paramètres de
                votre navigateur. Le consentement pour les cookies non
                essentiels est demandé lors de votre première visite sur notre
                plateforme et vous pouvez retirer votre consentement à tout
                moment.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Modifications de la Politique de Cookies</h1>
              <p>
                Nous pouvons mettre à jour cette Politique de Cookies de temps à
                autre pour refléter les changements dans nos pratiques ou les
                exigences légales. Une notification de toute mise à jour
                importante sera fournie via notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informations de contact</h1>
              <p>
                Pour toute question ou préoccupation concernant cette Politique
                de Cookies, veuillez nous contacter via la section 'Contact' sur
                la page d'accueil.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Política de Privacidade da App TurkAfrica B2B</h6>
              <h6>Última Atualização: 18 de setembro de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Bem-vindo à App TurkAfrica B2B, uma plataforma dedicada a
                promover conexões comerciais fortes entre a África e a Turquia.
                Esta Política de Privacidade explica como coletamos, usamos,
                protegemos e compartilhamos suas informações pessoais quando
                você usa nossos serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Informações que coletamos</h1>
              <p>
                Coletamos as seguintes informações pessoais quando você se
                cadastra em nossa plataforma, faz pedidos ou interage com nossos
                serviços:
                <br />• Informações de Identificação Pessoal: Inclui seu nome,
                endereço de e-mail, número de telefone e detalhes comerciais.
                <br />• Informações Transacionais: Detalhes de transações e
                pedidos feitos através de nossa plataforma.
                <br />• Dados de Uso: Informações sobre como você navega e usa
                nosso site e serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Como usamos seus dados</h1>
              <p>
                Utilizamos seus dados pessoais para:
                <br />• Fornecer nossos serviços: Processar transações, atender
                pedidos e oferecer suporte ao cliente.
                <br />• Melhorar nossa plataforma: Analisar o comportamento dos
                usuários para aprimorar a plataforma e desenvolver novos
                recursos.
                <br />• Marketing (com Consentimento): Enviar mensagens
                promocionais, ofertas especiais e oportunidades comerciais, mas
                apenas se você tiver optado por recebê-las.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartilhamento e Divulgação de Dados</h1>
              <p>
                Podemos compartilhar suas informações com:
                <br />• Prestadores de Serviços: Parceiros que nos ajudam a
                operar nossos negócios (por exemplo, processadores de
                pagamentos, serviços de logística).
                <br />• Conformidade Legal: Quando exigido por lei ou para
                proteger nossos direitos legais e interesses.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Segurança dos Dados</h1>
              <p>
                Levamos a segurança a sério e implementamos medidas de segurança
                padrão do setor, como criptografia e controles de acesso, para
                proteger suas informações pessoais contra acesso não autorizado,
                perda ou violação.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retenção de Dados</h1>
              <p>
                Mantemos suas informações pessoais pelo tempo necessário para
                fornecer nossos serviços ou conforme exigido por lei. Quando não
                forem mais necessárias, suas informações são excluídas ou
                anonimizadas de forma segura.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Seus Direitos</h1>
              <p>
                Sob o Regulamento Geral de Proteção de Dados (RGPD) do Reino
                Unido, você tem o direito de:
                <br />• Acessar seus dados pessoais.
                <br />• Corrigir dados incorretos ou incompletos.
                <br />• Solicitar a exclusão de seus dados.
                <br />• Restringir a forma como processamos seus dados.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferências Internacionais de Dados</h1>
              <p>
                Se suas informações pessoais forem transferidas para fora do
                Reino Unido, garantimos que elas estejam protegidas, cumprindo
                os quadros jurídicos aplicáveis, incluindo cláusulas contratuais
                padrão ou medidas equivalentes.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Informações de Contato</h1>
              <p>
                Para qualquer dúvida relacionada à privacidade ou para exercer
                seus direitos sob esta política, entre em contato conosco
                através da seção ‘Contato’ disponível em nossa página inicial.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Atualizações da Política</h1>
              <p>
                Podemos atualizar esta Política de Privacidade de tempos em
                tempos. Se forem feitas mudanças significativas, notificaremos
                você através de nossa plataforma ou por e-mail. Consulte
                periodicamente para se manter informado sobre as atualizações.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Política de Cookies da App TurkAfrica B2B</h6>
              <h6>Última Atualização: 18 de setembro de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Cookies são pequenos arquivos de texto armazenados em seu
                dispositivo para melhorar a experiência do usuário. Esta
                Política de Cookies explica como e por que usamos cookies na
                plataforma TurkAfrica B2B.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies que Usamos</h1>
              <p>
                Cookies Essenciais: Estes são necessários para as
                funcionalidades principais da plataforma, como logins seguros e
                processamento de transações.
                <br />
                Cookies Analíticos: Usados para coletar dados sobre como os
                usuários interagem com nossa plataforma, ajudando-nos a melhorar
                nossos serviços.
                <br />
                Cookies de Marketing: Nos ajudam a personalizar publicidade e
                mensagens promocionais com base em suas preferências e
                comportamento.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gerenciamento de Cookies</h1>
              <p>
                Você pode gerenciar ou bloquear cookies através das
                configurações do seu navegador. O consentimento para cookies não
                essenciais é solicitado quando você visita nossa plataforma pela
                primeira vez e você pode retirar o consentimento a qualquer
                momento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Alterações na Política de Cookies</h1>
              <p>
                Podemos atualizar esta Política de Cookies de tempos em tempos
                para refletir mudanças em nossas práticas ou requisitos legais.
                Avisos de qualquer atualização significativa serão fornecidos
                através de nossa plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informações de Contato</h1>
              <p>
                Para qualquer dúvida ou preocupação sobre esta Política de
                Cookies, entre em contato conosco através da seção ‘Contato’ na
                página inicial.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Sera ya Faragha ya App ya TurkAfrica B2B</h6>
              <h6>Imesasishwa Tarehe: 18 Septemba 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Karibu kwenye App ya TurkAfrica B2B, jukwaa linaloongoza
                linalojitolea kukuza uhusiano wa biashara kati ya Afrika na
                Türkiye. Sera hii ya Faragha inaeleza jinsi tunavyokusanya,
                kutumia, kulinda, na kushiriki maelezo yako binafsi unapotumia
                huduma zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Taarifa Tunazokusanya</h1>
              <p>
                Tunakusanya maelezo haya binafsi unaposajili kwenye jukwaa letu,
                kuweka maagizo, au kuingiliana na huduma zetu:
                <br />• Maelezo ya Utambulisho: Jina lako, barua pepe, namba ya
                simu, na maelezo ya biashara.
                <br />• Maelezo ya Muamala: Maelezo ya miamala na maagizo
                yaliyofanywa kupitia jukwaa letu.
                <br />• Data ya Matumizi: Taarifa kuhusu jinsi unavyotumia
                tovuti na huduma zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Tunavyotumia Data Yako</h1>
              <p>
                Tunatumia data yako binafsi kwa:
                <br />• Kutoa Huduma zetu: Kuchakata miamala, kutimiza maagizo,
                na kutoa usaidizi kwa wateja.
                <br />• Kuboresha Jukwaa Letu: Kuchanganua tabia za watumiaji
                ili kuboresha jukwaa na kuendeleza vipengele vipya.
                <br />• Masoko (Kwa idhini): Kutuma ujumbe wa promosheni na ofa
                maalum, lakini tu ikiwa umechagua kupokea.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Kushiriki na Kufichua Data</h1>
              <p>
                Tunaweza kushiriki maelezo yako na:
                <br />• Watoa Huduma: Washirika wanaotusaidia kuendesha shughuli
                zetu za biashara (k.m., wasindikaji wa malipo, huduma za vifaa).
                <br />• Majukumu ya Kisheria: Wakati sheria inahitaji au kulinda
                haki zetu za kisheria.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Usalama wa Data</h1>
              <p>
                Tunatekeleza hatua za usalama za kiwango cha sekta kama usimbaji
                fiche na udhibiti wa ufikiaji kulinda maelezo yako binafsi dhidi
                ya ufikiaji usioruhusiwa, upotezaji au uvunjaji.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Uhifadhi wa Data</h1>
              <p>
                Tunahifadhi maelezo yako binafsi kwa muda mrefu kama inahitajika
                kutoa huduma zetu au kama inavyotakiwa na sheria. Mara tu
                maelezo hayatakiwi tena, yanafutwa au kufanywa yasiyoweza
                kutambulika kwa usalama.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Haki Zako</h1>
              <p>
                Chini ya Kanuni ya Ulinzi wa Data ya Jumla ya Uingereza (GDPR),
                una haki ya kufikia maelezo yako binafsi, kusahihisha, kufuta au
                kuzuia usindikaji wa maelezo yako. Ili kutekeleza haki hizi,
                tafadhali wasiliana nasi kwa kutumia maelezo yaliyotolewa kwenye
                sehemu ya ‘Mawasiliano’.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uhamishaji wa Data Kimataifa</h1>
              <p>
                Ikiwa maelezo yako binafsi yatahamishwa nje ya Uingereza,
                tunahakikisha yanalindwa kwa kufuata mifumo ya kisheria husika.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali yoyote yanayohusiana na faragha, au kutekeleza haki
                zako katika sera hii, tafadhali wasiliana nasi kupitia sehemu ya
                ‘Mawasiliano’ inapatikana kwenye ukurasa wa kutua.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Mabadiliko ya Sera</h1>
              <p>
                Tunaweza kusasisha Sera hii ya Faragha mara kwa mara. Ikiwa
                mabadiliko makubwa yatafanywa, tutakujulisha kupitia jukwaa letu
                au kwa barua pepe. Tafadhali angalia mara kwa mara ili kupata
                habari kuhusu sasisho.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Sera ya Vidakuzi ya App ya TurkAfrica B2B</h6>
              <h6>Imesasishwa Tarehe: 18 Septemba 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Vidakuzi ni faili ndogo za maandishi zinazohifadhiwa kwenye
                kifaa chako ili kuboresha uzoefu wa mtumiaji. Sera hii ya
                Vidakuzi inaeleza jinsi na kwa nini tunatumia vidakuzi kwenye
                jukwaa la App ya TurkAfrica B2B.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Aina za Vidakuzi Tunazotumia</h1>
              <p>
                Vidakuzi Muhimu: Hivi ni muhimu kwa utendakazi wa msingi wa
                jukwaa, kama vile kuingia kwa usalama na uchakataji wa miamala.
                <br />
                Vidakuzi vya Takwimu: Vinatumiwa kukusanya data juu ya jinsi
                watumiaji wanavyotumia jukwaa letu, na kutusaidia kuboresha
                huduma zetu.
                <br />
                Vidakuzi vya Masoko: Vinatusaidia kubinafsisha matangazo na
                ujumbe wa promosheni kulingana na mapendeleo yako na tabia yako.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usimamizi wa Vidakuzi</h1>
              <p>
                Unaweza kudhibiti au kuzuia vidakuzi kupitia mipangilio ya
                kivinjari chako. Idhini ya vidakuzi visivyo muhimu huombwa
                wakati wa ziara yako ya kwanza kwenye jukwaa letu, na unaweza
                kuondoa idhini hiyo wakati wowote.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Mabadiliko katika Sera ya Vidakuzi</h1>
              <p>
                Tunaweza kusasisha Sera hii ya Vidakuzi mara kwa mara ili
                kuakisi mabadiliko katika mazoea yetu au mahitaji ya kisheria.
                Ilani ya sasisho zozote muhimu itatolewa kupitia jukwaa letu.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali au wasiwasi wowote kuhusu Sera hii ya Vidakuzi,
                tafadhali wasiliana nasi kupitia sehemu ya ‘Mawasiliano’ kwenye
                ukurasa wa kutua.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Política de Privacidad de la Aplicación TurkAfrica B2B</h6>
              <h6>Última Actualización: 18 de septiembre de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Bienvenido a la Aplicación TurkAfrica B2B, una plataforma líder
                dedicada a fomentar conexiones comerciales sólidas entre África
                y Turquía. Esta Política de Privacidad explica cómo recopilamos,
                usamos, protegemos y compartimos su información personal cuando
                utiliza nuestros servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Información que recopilamos</h1>
              <p>
                Recopilamos la siguiente información personal cuando se registra
                en nuestra plataforma, realiza pedidos o interactúa con nuestros
                servicios:
                <br />• Información de Identificación Personal: Incluye su
                nombre, dirección de correo electrónico, número de teléfono y
                detalles comerciales.
                <br />• Información Transaccional: Detalles de las transacciones
                realizadas a través de nuestra plataforma.
                <br />• Datos de Uso: Información sobre cómo navega y utiliza
                nuestro sitio web y servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Cómo usamos sus datos</h1>
              <p>
                Utilizamos sus datos personales para:
                <br />• Proporcionar nuestros servicios: Procesar transacciones,
                cumplir pedidos y ofrecer asistencia al cliente.
                <br />• Mejorar nuestra plataforma: Analizar el comportamiento
                de los usuarios para mejorar la plataforma y desarrollar nuevas
                funciones.
                <br />• Marketing (Con consentimiento): Enviar mensajes
                promocionales, ofertas especiales y oportunidades comerciales,
                pero solo si ha dado su consentimiento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartición y Divulgación de Datos</h1>
              <p>
                Podemos compartir su información con:
                <br />• Proveedores de Servicios: Socios que nos ayudan a operar
                nuestros negocios (por ejemplo, procesadores de pagos, servicios
                de logística).
                <br />• Cumplimiento legal: Cuando la ley lo requiera o para
                proteger nuestros derechos legales e intereses.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Seguridad de los Datos</h1>
              <p>
                Tomamos la seguridad en serio e implementamos medidas de
                seguridad estándar de la industria, como cifrado y controles de
                acceso, para proteger su información personal contra acceso no
                autorizado, pérdida o violación.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retención de Datos</h1>
              <p>
                Conservamos su información personal durante el tiempo necesario
                para cumplir con nuestros servicios o según lo requiera la ley.
                Una vez que ya no sea necesario, sus datos se eliminan o se
                anonimizarán de forma segura.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Sus Derechos</h1>
              <p>
                Bajo el Reglamento General de Protección de Datos (RGPD) del
                Reino Unido, usted tiene el derecho de:
                <br />• Acceder a sus datos personales.
                <br />• Corregir datos inexactos o incompletos.
                <br />• Solicitar la eliminación de sus datos.
                <br />• Restringir cómo procesamos sus datos.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferencias Internacionales de Datos</h1>
              <p>
                Si su información personal se transfiere fuera del Reino Unido,
                garantizamos que esté protegida cumpliendo con los marcos
                legales aplicables, incluidas cláusulas contractuales estándar o
                medidas equivalentes.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Información de Contacto</h1>
              <p>
                Para cualquier consulta relacionada con la privacidad o para
                ejercer sus derechos en virtud de esta política, comuníquese con
                nosotros a través de la sección 'Contacto' disponible en nuestra
                página de inicio.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Actualizaciones de la Política</h1>
              <p>
                Podemos actualizar esta Política de Privacidad de vez en cuando.
                Si se realizan cambios significativos, le notificaremos a través
                de nuestra plataforma o por correo electrónico. Consulte
                periódicamente para mantenerse informado sobre las
                actualizaciones.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Política de Cookies de la Aplicación TurkAfrica B2B</h6>
              <h6>Última Actualización: 18 de septiembre de 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Las cookies son pequeños archivos de texto almacenados en su
                dispositivo para mejorar la experiencia del usuario. Esta
                Política de Cookies explica cómo y por qué utilizamos cookies en
                la plataforma de la aplicación TurkAfrica B2B.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies que Usamos</h1>
              <p>
                Cookies Esenciales: Estas son necesarias para las
                funcionalidades principales de la plataforma, como inicios de
                sesión seguros y procesamiento de transacciones.
                <br />
                Cookies Analíticas: Se utilizan para recopilar datos sobre cómo
                los usuarios interactúan con nuestra plataforma, lo que nos
                ayuda a mejorar nuestros servicios.
                <br />
                Cookies de Marketing: Nos ayudan a personalizar la publicidad y
                los mensajes promocionales en función de sus preferencias y
                comportamiento.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestión de Cookies</h1>
              <p>
                Puede gestionar o bloquear las cookies a través de la
                configuración de su navegador. El consentimiento para cookies no
                esenciales se solicita cuando visita nuestra plataforma por
                primera vez y puede retirar su consentimiento en cualquier
                momento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Cambios en la Política de Cookies</h1>
              <p>
                Podemos actualizar esta Política de Cookies de vez en cuando
                para reflejar cambios en nuestras prácticas o requisitos
                legales. Se proporcionará un aviso de cualquier actualización
                importante a través de nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Información de Contacto</h1>
              <p>
                Para cualquier consulta o inquietud sobre esta Política de
                Cookies, comuníquese con nosotros a través de la sección
                'Contacto' en la página de inicio.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>TurkAfrica B2B App Privacy Policy</h6>
              <h6>Last Updated: September 18, 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Welcome to the TurkAfrica B2B App, a leading
                business-to-business (B2B) platform dedicated to fostering
                strong business connections between Africa and Türkiye. This
                Privacy Policy explains how we collect, use, protect, and share
                your personal information when you use our services.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Information We Collect</h1>
              <p>
                We collect the following personal information when you register
                on our platform, place orders, or interact with our services:
                <br />• Personal Identification Information: Includes your name,
                email address, phone number, and business details.
                <br />• Transactional Information: Details of transactions and
                orders made through our platform.
                <br />• Usage Data: Information on how you navigate and use our
                website and services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. How We Use Your Data</h1>
              <p>
                We use your personal data to:
                <br />• Provide Our Services: Process transactions, fulfill
                orders, and offer customer support.
                <br />• Improve Our Platform: Analyze user behavior to enhance
                the platform and develop new features.
                <br />• Marketing (With Consent): Send promotional messages,
                special offers, and business opportunities, but only if you have
                opted in.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Data Sharing and Disclosure</h1>
              <p>
                We may share your information with:
                <br />• Service Providers: Partners who help us run our business
                operations (e.g., payment processors, logistics services).
                <br />• Legal Compliance: When required by law or to protect our
                legal rights and interests.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Data Security</h1>
              <p>
                We take security seriously and implement industry-standard
                security measures, such as encryption and access controls, to
                protect your personal information from unauthorized access,
                loss, or breach.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Data Retention</h1>
              <p>
                We retain your personal information for as long as it is needed
                to fulfill our services or as required by law. Once no longer
                needed, your data is securely deleted or anonymized.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Your Rights</h1>
              <p>
                Under the UK General Data Protection Regulation (GDPR), you have
                the right to:
                <br />• Access your personal data.
                <br />• Correct inaccurate or incomplete data.
                <br />• Request deletion of your data.
                <br />• Restrict how we process your data.
              </p>
            </div>
            <div className="text_div">
              <h1>8. International Data Transfers</h1>
              <p>
                If your personal information is transferred outside the UK, we
                ensure it is protected by complying with applicable legal
                frameworks, including standard contractual clauses or equivalent
                measures.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Contact Information</h1>
              <p>
                For any privacy-related inquiries, or to exercise your rights
                under this policy, please reach out to us through the ‘Contact’
                section available on our landing page.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Policy Updates</h1>
              <p>
                We may update this Privacy Policy from time to time. If
                significant changes are made, we will notify you through our
                platform or via email. Please check back periodically to stay
                informed of updates.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>TurkAfrica B2B App Cookie Policy</h6>
              <h6>Last Updated: September 18, 2024</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Cookies are small text files stored on your device to improve
                user experience. This Cookie Policy explains how and why we use
                cookies on the TurkAfrica B2B App platform.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types of Cookies We Use</h1>
              <p>
                Essential Cookies: These are necessary for the core
                functionalities of the platform, such as secure log-ins and
                transaction processing.
                <br />
                Analytics Cookies: Used to collect data on how users interact
                with our platform, helping us improve our services.
                <br />
                Marketing Cookies: Help us tailor advertising and promotional
                messages based on your preferences and behavior.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Managing Your Cookies</h1>
              <p>
                You can manage or block cookies through your browser’s settings.
                Consent for non-essential cookies is requested when you first
                visit our platform, and you can withdraw consent at any time.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Changes to the Cookie Policy</h1>
              <p>
                We may update this Cookie Policy from time to time to reflect
                changes in our practices or legal requirements. Notice of any
                significant updates will be provided through our platform.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Contact Information</h1>
              <p>
                For any questions or concerns about this Cookie Policy, please
                contact us through the ‘Contact’ section on the landing page.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
