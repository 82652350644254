import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img12, name: "Negócio Agrícola" },
    { id: 2, img: img3, name: "Investidor" },
    { id: 3, img: img4, name: "Produtor de Café" },
    { id: 4, img: img9, name: "Importador de Café" },
    { id: 5, img: img8, name: "Varejista" },
    { id: 6, img: img11, name: "Clínica de Beleza" },
    { id: 7, img: img2, name: "Companhia de Mineração" },
    { id: 8, img: img7, name: "Peças de Reposição Automotivas" },
    { id: 9, img: img10, name: "Empresa de Design de Interiores e Exteriores" },
    { id: 10, img: img6, name: "Atacadista de Fabricação Têxtil" },
    { id: 11, img: img5, name: "Advogado de Negócios/Advogado Corporativo" },
    { id: 12, img: img1, name: "Fabricante de Decoração e Móveis" },
  ];
  return (
    <div className="Section3" id="Aboutus">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Sobre Nós</h1>
          <p>
            Desbloqueie Oportunidades de Negócios Sem Precedentes com o
            <span>App B2B TurkAfrica!</span>
          </p>
          <p>
            Você é um empreendedor ou proprietário de negócios na África ou na
            Turquia? Imagine conectar-se com mais de
            <span>
              52 milhões de PMEs na África e 3,2 milhões de PMEs na Turquia,
            </span>
            todos ansiosos para expandir seus horizontes e explorar novos
            mercados. Com mais de <span>1,5 bilhão de consumidores</span>{" "}
            aguardando para serem atendidos, o potencial de crescimento é
            ilimitado.
          </p>
          <p>
            <span>Apresentando o App B2B TurkAfrica — </span>sua porta de
            entrada para um mundo de possibilidades! Seja você um distribuidor,
            fabricante, exportador, importador, investidor, licenciador,
            licenciado, atacadista ou varejista, o App B2B TurkAfrica é
            projetado para ajudá-lo a fazer networking, fechar negócios e formar
            parcerias poderosas que transcendem fronteiras.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
