import React from "react";
import "./Newsection.css";
import sec6img from "../../../../assets/Newsection.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";

const Index = () => {
  return (
    <div className="Newsection" id="Investors">
      <div className="submain_Newsection_div">
        <div className="Newsection_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection_text_div">
          <h1>
            Ushirika na Fursa <br /> za Uwekezaji
          </h1>
          <p>
            Kama una nia ya kutafuta fursa za kuwa mwanahisa anayethaminiwa au
            unataka kuwekeza katika <b>TurkAfrica</b>, tunakukaribisha kwa moyo
            mkunjufu uwasiliane nasi. Kwa maelezo zaidi na kuanzisha mazungumzo,
            tafadhali elekea kwenye sehemu ya 'Mawasiliano'. Tunatarajia kwa
            hamu fursa ya kuunganisha nawe.
          </p>
          <div className="Newsection_store_div">
            <p>Programu Inakuja Hivi Karibuni</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
