import React from "react";
import "./Newsection2.css";
import sec6img from "../../../../assets/why_wait.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const Index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div">
        <div className="Newsection2_text_div">
          <h1>Por Que Esperar?</h1>
          <p>
            <span>Descubra Novos Mercados:</span> Acesse uma vasta rede de
            empresas e consumidores em toda a África e Turquia.
          </p>
          <p>
            <span>Forme Parcerias Estratégicas:</span>
            Conecte-se diretamente com parceiros potenciais, investidores e
            associações empresariais.
          </p>
          <p>
            <span>Converse Gratuitamente:</span>
            Comece conversas instantaneamente com empresas que compartilham sua
            visão — sem custo.
          </p>
          <p>
            Baixe o App B2B TurkAfrica <span>GRATUITAMENTE</span> hoje e dê o
            primeiro passo para expandir seu negócio globalmente. O futuro do
            seu negócio está a um toque de distância!
          </p>
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Não Perca—Junte-se a Nós Agora!
          </Link>
        </div>
        <div className="Newsection2_img_div">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
