import { useState, useEffect, useRef } from "react";
import "./nav.css";
import logo from "../../../assets/Logo.png";
import logo2 from "../../../assets/Logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setlang] = useState();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const toggle = (item) => {
    // console.log("navbar item clicked", item);
    setIsopen(false);
    // setshow(false);
  };
  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);

  const Langchngae = (e) => {
    const Link = e.target.value;
    Navigate(`${Link}`);
  };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar" style={{ justifyContent: "space-between" }}>
          <Link to="Sec1" className="logo">
            <img src={logo} alt="" style={{ width: "100%" }} />
          </Link>
          <Link to="Sec1" className="logo2">
            <img src={logo2} alt="" style={{ width: "70%" }} />
          </Link>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            {/* <li>
              <NavLink to="/" onClick={() => toggle("")}>
                Home
              </NavLink>
            </li> */}
            <li>
              <NavLink to="" onClick={() => toggle("")}>
                About
              </NavLink>
            </li>

            <li>
              <NavLink to="" onClick={() => toggle("")}>
                Contact
              </NavLink>
            </li>

            <li>
              <select name="" id="Lang">
                <option value="1">Industries</option>
                <option value="2">Industries1</option>
                <option value="3">Industries2</option>
                <option value="4">Industries3</option>
                <option value="5">Industries4</option>
                <option value="6">Industries5</option>
              </select>
            </li>
            <li>
              <button className="download_btn">
                <NavLink to="" offset={-100} className="loginlink">
                  Log in
                </NavLink>
              </button>
            </li>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
        {/* <Outlet /> */}
      </div>
    </div>
  );
};

export default Navbar;
// path name :-  "/"          language name :-English
// path name :-  "/Turkish"   language name :-Turkish
// path name :-   "/French"   language name :-French
// path name :-  "/Portuguese" language name :-Portuguese
// path name :-  "/Spanish"   language name :-Spanish
// path name :-  "/Swahili"   language name :-Swahili
