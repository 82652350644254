// import { useState, useEffect, useRef } from "react";
// import "./nav.css";
// import logo from "../../../assets/Logo.png";
// import logo2 from "../../../assets/Logo2.png";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Fade as Hamburger } from "hamburger-react";
// import { Link } from "react-scroll";

// const Navbar = () => {
//   const [screenSize, setScreenSize] = useState(getCurrentDimension());
//   const [lang, setlang] = useState();
//   console.log("🚀 ~ Navbar ~ lang:", lang);
//   const location = useLocation();
//   function getCurrentDimension() {
//     return {
//       width: window.innerWidth,
//       height: window.innerHeight,
//     };
//   }
//   useEffect(() => {
//     const updateDimension = () => {
//       setScreenSize(getCurrentDimension());
//     };
//     window.addEventListener("resize", updateDimension);

//     return () => {
//       window.removeEventListener("resize", updateDimension);
//     };
//   }, [screenSize]);
//   const Navigate = useNavigate();

//   let menuref = useRef();

//   const [open, setIsopen] = useState(false);
//   const [onshow, setonshow] = useState(false);

//   useEffect(() => {
//     let handler = (event) => {
//       if (menuref.current) {
//         if (!menuref.current.contains(event.target)) {
//           setIsopen(false);
//           setonshow(false);
//           // setshow(false);
//         }
//       }
//     };
//     document.addEventListener("mousedown", handler);
//     document.addEventListener("scroll", handler);

//     return () => {
//       document.removeEventListener("mousedown", handler);
//       document.removeEventListener("scroll", handler);
//     };
//   }, []);
//   const toggle = (item) => {
//     // console.log("navbar item clicked", item);
//     setIsopen(false);
//     // setshow(false);
//   };
//   const handlehide = () => {
//     setIsopen(!open);
//     // setshow(false);
//   };
//   const pathname = useLocation();
//   useEffect(() => {
//     setlang(pathname.pathname);
//   }, [pathname]);

//   const Langchngae = (e) => {
//     const Link = e.target.value;
//     Navigate(`${Link}`);
//   };

//   useEffect(() => {
//     if (location.pathname === "/" && location.hash === "#Aboutus") {
//       const section = document.getElementById("Aboutus");
//       if (section) {
//         const yOffset = -100;
//         const y =
//           section.getBoundingClientRect().top + window.pageYOffset + yOffset;
//         window.scrollTo({ top: y, behavior: "smooth" });
//       }
//     }
//     if (location.pathname === "/" && location.hash === "#Download") {
//       const section = document.getElementById("Download");
//       if (section) {
//         const yOffset = -100;
//         const y =
//           section.getBoundingClientRect().top + window.pageYOffset + yOffset;
//         window.scrollTo({ top: y, behavior: "smooth" });
//       }
//     }

//     if (location.pathname === "/" && location.hash === "#Investors") {
//       const section = document.getElementById("Investors");
//       if (section) {
//         const yOffset = -100;
//         const y =
//           section.getBoundingClientRect().top + window.pageYOffset + yOffset;
//         window.scrollTo({ top: y, behavior: "smooth" });
//       }
//     }
//     if (location.pathname === "/" && location.hash === "#Contact") {
//       const section = document.getElementById("Contact");
//       if (section) {
//         const yOffset = -100;
//         const y =
//           section.getBoundingClientRect().top + window.pageYOffset + yOffset;
//         window.scrollTo({ top: y, behavior: "smooth" });
//       }
//     }
//   }, [location]);

//   return (
//     <div ref={menuref} className="Main_navbar">
//       <div className="mainnav">
//         <nav id="navbar">
//           <NavLink
//             to={{
//               pathname:
//                 lang == "/Turkish"
//                   ? "/Turkish"
//                   : lang == "/French"
//                   ? "/French"
//                   : lang == "/Portuguese"
//                   ? "/Portuguese"
//                   : lang == "/Swahili"
//                   ? "/Swahili"
//                   : lang == "/Spanish"
//                   ? "/Spanish"
//                   : "/",
//               hash: "#Aboutus",
//             }}
//             className="logo">
//             <img src={logo} alt="" style={{ width: "100%" }} />
//           </NavLink>

//           <NavLink
//             to={{
//               pathname:
//                 lang == "/Turkish"
//                   ? "/Turkish"
//                   : lang == "/French"
//                   ? "/French"
//                   : lang == "/Portuguese"
//                   ? "/Portuguese"
//                   : lang == "/Swahili"
//                   ? "/Swahili"
//                   : lang == "/Spanish"
//                   ? "/Spanish"
//                   : "/",
//               hash: "#Aboutus",
//             }}
//             className="logo2">
//             <img src={logo2} alt="" style={{ width: "70%" }} />
//           </NavLink>

//           <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
//             <li onMouseMove={() => setonshow(true)}>
//               <NavLink
//                 to={{
//                   pathname:
//                     lang == "/Turkish"
//                       ? "/Turkish"
//                       : lang == "/French"
//                       ? "/French"
//                       : lang == "/Portuguese"
//                       ? "/Portuguese"
//                       : lang == "/Swahili"
//                       ? "/Swahili"
//                       : lang == "/Spanish"
//                       ? "/Spanish"
//                       : "/",
//                   hash: "#Aboutus",
//                 }}
//                 offset={-100}
//                 onClick={() => toggle("")}>
//                 {lang == "/Turkish"
//                   ? "Hakkında"
//                   : lang == "/French"
//                   ? "À Propos"
//                   : lang == "/Portuguese"
//                   ? "Sobre"
//                   : lang == "/Swahili"
//                   ? "Kuhusu"
//                   : lang == "/Spanish"
//                   ? "Acerca de"
//                   : "About"}
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={{
//                   pathname:
//                     lang == "/Turkish"
//                       ? "/Turkish"
//                       : lang == "/French"
//                       ? "/French"
//                       : lang == "/Portuguese"
//                       ? "/Portuguese"
//                       : lang == "/Swahili"
//                       ? "/Swahili"
//                       : lang == "/Spanish"
//                       ? "/Spanish"
//                       : "/",
//                   hash: "#Download",
//                 }}
//                 offset={-100}
//                 onClick={() => toggle("")}>
//                 {lang == "/Turkish"
//                   ? "Uygulamayı İndir"
//                   : lang == "/French"
//                   ? "Télécharger l'App"
//                   : lang == "/Portuguese"
//                   ? "Baixar App"
//                   : lang == "/Swahili"
//                   ? "Pakua Programu"
//                   : lang == "/Spanish"
//                   ? "Descargar App"
//                   : "Download App"}
//               </NavLink>
//             </li>

//             <li>
//               <NavLink
//                 to={{
//                   pathname:
//                     lang == "/Turkish"
//                       ? "/Turkish"
//                       : lang == "/French"
//                       ? "/French"
//                       : lang == "/Portuguese"
//                       ? "/Portuguese"
//                       : lang == "/Swahili"
//                       ? "/Swahili"
//                       : lang == "/Spanish"
//                       ? "/Spanish"
//                       : "/",
//                   hash: "#Investors",
//                 }}
//                 offset={-100}
//                 onClick={() => toggle("")}>
//                 {lang == "/Turkish"
//                   ? "Yatırımcılar"
//                   : lang == "/French"
//                   ? "Investisseurs"
//                   : lang == "/Portuguese"
//                   ? "Investidores"
//                   : lang == "/Swahili"
//                   ? "Wawekezaji"
//                   : lang == "/Spanish"
//                   ? "Inversionistas"
//                   : "Investors"}
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to={{
//                   pathname:
//                     lang == "/Turkish"
//                       ? "/Turkish"
//                       : lang == "/French"
//                       ? "/French"
//                       : lang == "/Portuguese"
//                       ? "/Portuguese"
//                       : lang == "/Swahili"
//                       ? "/Swahili"
//                       : lang == "/Spanish"
//                       ? "/Spanish"
//                       : "/",
//                   hash: "#Contact",
//                 }}
//                 offset={-100}
//                 onClick={() => toggle("")}>
//                 {lang == "/Turkish"
//                   ? "İletişim"
//                   : lang == "/French"
//                   ? "Contact"
//                   : lang == "/Portuguese"
//                   ? "Contato"
//                   : lang == "/Swahili"
//                   ? "Wasiliana"
//                   : lang == "/Spanish"
//                   ? "Contacto"
//                   : "Contact"}
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to="/Support" onClick={() => toggle("")}>
//                 {lang == "/Turkish"
//                   ? "Destek"
//                   : lang == "/French"
//                   ? "Support"
//                   : lang == "/Portuguese"
//                   ? "Suporte"
//                   : lang == "/Swahili"
//                   ? "Msaada"
//                   : lang == "/Spanish"
//                   ? "Apoyo"
//                   : "Support"}
//               </NavLink>
//             </li>

//             <select name="Lang" id="Lang" value={lang} onChange={Langchngae}>
//               <option value="/">English</option>
//               <option value="/Turkish">Turkish</option>
//               <option value="/French">French</option>
//               <option value="/Portuguese">Portuguese</option>
//               <option value="/Spanish">Spanish</option>
//               <option value="/Swahili">Swahili</option>
//             </select>
//           </ul>

//           <div className="menubtn">
//             <Hamburger onToggle={handlehide} toggled={open} />
//           </div>
//         </nav>
//       </div>
//     </div>
//   );
// };

// export default Navbar;
// path name :-  "/"          language name :-English
// path name :-  "/Turkish"   language name :-Turkish
// path name :-   "/French"   language name :-French
// path name :-  "/Portuguese" language name :-Portuguese
// path name :-  "/Spanish"   language name :-Spanish
// path name :-  "/Swahili"   language name :-Swahili
// <div ref={menuref} className="Main_navbar">
//   <div className="mainnav">
//     <nav id="navbar">
//       {lang === "/Support" ? (
//         <NavLink to="/" className="logo">
//           <img src={logo} alt="" style={{ width: "100%" }} />
//         </NavLink>
//       ) : (
//         <Link to="Sec3" className="logo">
//           <img src={logo} alt="" style={{ width: "100%" }} />
//         </Link>
//       )}
//       {lang === "/Support" ? (
//         <NavLink to="/" className="logo2">
//           <img src={logo2} alt="" style={{ width: "70%" }} />
//         </NavLink>
//       ) : (
//         <Link to="Aboutus" className="logo2">
//           <img src={logo2} alt="" style={{ width: "70%" }} />
//         </Link>
//       )}

//       <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
//         {/* <li>
//           <Link to="Sec1" onClick={() => toggle("")}>
//             {lang == "/Turkish"
//               ? "Ana Sayfa"
//               : lang == "/French"
//               ? "Accueil"
//               : lang == "/Portuguese"
//               ? "Início"
//               : lang == "/Swahili"
//               ? "Nyumbani"
//               : lang == "/Spanish"
//               ? "Inicio"
//               : "Home"}
//           </Link>
//         </li> */}
//         <li onMouseMove={() => setonshow(true)}>
//           {lang === "/Support" ? (
//             <NavLink
//               to={{ pathname: "/", hash: "#Aboutus" }}
//               offset={-100}
//               onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "Hakkında"
//                 : lang == "/French"
//                 ? "À Propos"
//                 : lang == "/Portuguese"
//                 ? "Sobre"
//                 : lang == "/Swahili"
//                 ? "Kuhusu"
//                 : lang == "/Spanish"
//                 ? "Acerca de"
//                 : "About"}
//             </NavLink>
//           ) : (
//             <Link to="Aboutus" offset={-100} onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "Hakkında"
//                 : lang == "/French"
//                 ? "À Propos"
//                 : lang == "/Portuguese"
//                 ? "Sobre"
//                 : lang == "/Swahili"
//                 ? "Kuhusu"
//                 : lang == "/Spanish"
//                 ? "Acerca de"
//                 : "About"}
//             </Link>
//           )}
//         </li>
//         <li>
//           {lang === "/Support" ? (
//             <NavLink
//               to={{ pathname: "/", hash: "#Download" }}
//               offset={-100}
//               onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "Uygulamayı İndir"
//                 : lang == "/French"
//                 ? "Télécharger l'App"
//                 : lang == "/Portuguese"
//                 ? "Baixar App"
//                 : lang == "/Swahili"
//                 ? "Pakua Programu"
//                 : lang == "/Spanish"
//                 ? "Descargar App"
//                 : "Download App"}
//             </NavLink>
//           ) : (
//             <Link to="Download" offset={-100}>
//               {lang == "/Turkish"
//                 ? "Uygulamayı İndir"
//                 : lang == "/French"
//                 ? "Télécharger l'App"
//                 : lang == "/Portuguese"
//                 ? "Baixar App"
//                 : lang == "/Swahili"
//                 ? "Pakua Programu"
//                 : lang == "/Spanish"
//                 ? "Descargar App"
//                 : "Download App"}
//             </Link>
//           )}
//         </li>

//         <li>
//           {lang === "/Support" ? (
//             <NavLink
//               to={{ pathname: "/", hash: "#Investors" }}
//               offset={-100}
//               onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "Yatırımcılar"
//                 : lang == "/French"
//                 ? "Investisseurs"
//                 : lang == "/Portuguese"
//                 ? "Investidores"
//                 : lang == "/Swahili"
//                 ? "Wawekezaji"
//                 : lang == "/Spanish"
//                 ? "Inversionistas"
//                 : "Investors"}
//             </NavLink>
//           ) : (
//             <Link to="Investors" offset={-100} onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "Yatırımcılar"
//                 : lang == "/French"
//                 ? "Investisseurs"
//                 : lang == "/Portuguese"
//                 ? "Investidores"
//                 : lang == "/Swahili"
//                 ? "Wawekezaji"
//                 : lang == "/Spanish"
//                 ? "Inversionistas"
//                 : "Investors"}
//             </Link>
//           )}
//         </li>
//         <li>
//           {lang === "/Support" ? (
//             <NavLink
//               to={{ pathname: "/", hash: "#Contact" }}
//               offset={-100}
//               onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "İletişim"
//                 : lang == "/French"
//                 ? "Contact"
//                 : lang == "/Portuguese"
//                 ? "Contato"
//                 : lang == "/Swahili"
//                 ? "Wasiliana"
//                 : lang == "/Spanish"
//                 ? "Contacto"
//                 : "Contact"}
//             </NavLink>
//           ) : (
//             <Link to="Contact" offset={-100} onClick={() => toggle("")}>
//               {lang == "/Turkish"
//                 ? "İletişim"
//                 : lang == "/French"
//                 ? "Contact"
//                 : lang == "/Portuguese"
//                 ? "Contato"
//                 : lang == "/Swahili"
//                 ? "Wasiliana"
//                 : lang == "/Spanish"
//                 ? "Contacto"
//                 : "Contact"}
//             </Link>
//           )}
//         </li>
//         <li>
//           <NavLink to="/Support" onClick={() => toggle("")}>
//             {lang == "/Turkish"
//               ? "Destek"
//               : lang == "/French"
//               ? "Support"
//               : lang == "/Portuguese"
//               ? "Suporte"
//               : lang == "/Swahili"
//               ? "Msaada"
//               : lang == "/Spanish"
//               ? "Apoyo"
//               : "Support"}
//           </NavLink>
//         </li>

//         <select name="Lang" id="Lang" value={lang} onChange={Langchngae}>
//           <option value="/">English</option>
//           <option value="/Turkish">Turkish</option>
//           <option value="/French">French</option>
//           <option value="/Portuguese">Portuguese</option>
//           <option value="/Spanish">Spanish</option>
//           <option value="/Swahili">Swahili</option>
//         </select>
//       </ul>

//       <div className="menubtn">
//         <Hamburger onToggle={handlehide} toggled={open} />
//       </div>
//     </nav>
//     {/* <Outlet /> */}
//   </div>
// </div>

import { useState, useEffect, useRef } from "react";
import "./nav.css";
import logo from "../../../assets/Logo.png";
import logo2 from "../../../assets/Logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";

const Navbar = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setLang] = useState("/");
  const location = useLocation();
  const navigate = useNavigate();
  const menuref = useRef();
  const [open, setIsOpen] = useState(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  // Handle language change and navigate
  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    setLang(selectedLang);
    navigate(selectedLang);
  };

  // Scroll effect
  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        const yOffset = -100;
        const y =
          section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  // Update lang based on pathname (only for language routes)
  useEffect(() => {
    const langPaths = [
      "/Turkish",
      "/French",
      "/Portuguese",
      "/Swahili",
      "/Spanish",
    ];
    if (langPaths.includes(location.pathname)) {
      setLang(location.pathname);
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpen(!open);
  };

  // Translations for navbar items
  const translations = {
    "/Turkish": {
      about: "Hakkında",
      download: "Uygulamayı İndir",
      investors: "Yatırımcılar",
      contact: "İletişim",
      support: "Destek",
    },
    "/French": {
      about: "À Propos",
      download: "Télécharger l'App",
      investors: "Investisseurs",
      contact: "Contact",
      support: "Support",
    },
    "/Portuguese": {
      about: "Sobre",
      download: "Baixar App",
      investors: "Investidores",
      contact: "Contato",
      support: "Suporte",
    },
    "/Swahili": {
      about: "Kuhusu",
      download: "Pakua Programu",
      investors: "Wawekezaji",
      contact: "Wasiliana",
      support: "Msaada",
    },
    "/Spanish": {
      about: "Acerca de",
      download: "Descargar App",
      investors: "Inversionistas",
      contact: "Contacto",
      support: "Apoyo",
    },
    "/": {
      about: "About",
      download: "Download App",
      investors: "Investors",
      contact: "Contact",
      support: "Support",
    },
  };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <NavLink
            to={{
              pathname: lang,
              hash: "#Aboutus",
            }}
            className="logo">
            <img src={logo} alt="Logo" style={{ width: "100%" }} />
          </NavLink>
          <NavLink
            to={{
              pathname: lang,
              hash: "#Aboutus",
            }}
            className="logo2">
            <img src={logo2} alt="" style={{ width: "70%" }} />
          </NavLink>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Aboutus" }}
                onClick={toggleMenu}>
                {translations[lang]?.about}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Download" }}
                onClick={toggleMenu}>
                {translations[lang]?.download}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Investors" }}
                onClick={toggleMenu}>
                {translations[lang]?.investors}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Contact" }}
                onClick={toggleMenu}>
                {translations[lang]?.contact}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Support" onClick={toggleMenu}>
                {translations["/"]?.support}
              </NavLink>
            </li>

            <select
              name="Lang"
              id="Lang"
              value={lang}
              onChange={handleLanguageChange}>
              <option value="/">English</option>
              <option value="/Turkish">Turkish</option>
              <option value="/French">French</option>
              <option value="/Portuguese">Portuguese</option>
              <option value="/Spanish">Spanish</option>
              <option value="/Swahili">Swahili</option>
            </select>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={toggleMenu} toggled={open} />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
