import React from "react";
import "./Newsection2.css";
import sec6img from "../../../../assets/why_wait.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const Index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div">
        <div className="Newsection2_text_div">
          <h1>Pourquoi Attendre?</h1>
          <p>
            <span>Découvrez De Nouveaux Marchés:</span> Accédez à un vaste
            réseau d'entreprises et de consommateurs à travers l'Afrique et la
            Turquie.
          </p>
          <p>
            <span>Formez des Partenariats Stratégiques:</span>
            Connectez-vous directement avec des partenaires potentiels, des
            investisseurs et des associations d'entreprises.
          </p>
          <p>
            <span>Discutez Gratuitement:</span>
            Commencez des conversations instantanément avec des entreprises
            partageant votre vision — sans frais.
          </p>
          <p>
            Téléchargez l'application B2B TurkAfrica <span>GRATUITEMENT</span>{" "}
            aujourd'hui et faites le premier pas pour développer votre
            entreprise à l'échelle mondiale. L'avenir de votre entreprise n'est
            qu'à un clic!
          </p>
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Ne Ratez Pas l'Occasion—Rejoignez-nous Maintenant!
          </Link>
        </div>
        <div className="Newsection2_img_div">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
