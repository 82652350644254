import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const [lang, setlang] = useState();
  console.log("🚀 ~ Index ~ lang:", lang);
  const pathname = useLocation();
  const getname = pathname?.state?.pathname?.pathname;
  useEffect(() => {
    setlang(getname);
  }, [pathname]);
  const Navigation = useNavigate();
  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
              ? Navigation("/French")
              : lang == "/Portuguese"
              ? Navigation("/Portuguese")
              : lang == "/Swahili"
              ? Navigation("/Swahili")
              : lang == "/Spanish"
              ? Navigation("/Spanish")
              : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>
      <h3 className="head_text">
        {lang == "/Turkish"
          ? "TürkAfrica.Market Kuralları"
          : lang == "/French"
          ? "Directives de TürkAfrica.Market"
          : lang == "/Portuguese"
          ? "Diretrizes da TürkAfrica.Market"
          : lang == "/Swahili"
          ? "Miongozo ya TürkAfrica.Market"
          : lang == "/Spanish"
          ? "Guías de TürkAfrica.Market"
          : "TürkAfrica.Market Guidelines"}
      </h3>
      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>Ayrımcılık, Nefret ve Anti-Semitizm</h1>
              <p>
                TürkAfrica.Market, ayrımcılığa, nefrete, ırkçılığa ve
                anti-Semitizme sıfır tolerans gösteren kapsayıcı bir
                platformdur. Nefret söylemi içeren veya nefret davranışını
                teşvik eden içeriklere izin vermiyoruz. Platformumuz dışında
                nefret söylemiyle ilişkili veya nefret söylemi yapan hesaplar
                askıya alınacak veya yasaklanacaktır. <br />
                Nefret söylemi veya nefret dolu davranışları, korunan
                özelliklere dayalı olarak bir bireye veya gruba saldıran, tehdit
                eden, şiddet teşvik eden veya diğer şekillerde insanlık dışı
                muamelede bulunan içerikler olarak tanımlıyoruz: Kast,
                Engellilik, Etnik köken, Cinsiyet, Ulusal köken, Irk, Din ve
                Cinsel yönelim.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Bir bireye veya gruba yönelik nefret dolu içerikler,
                    aşağılık iddiaları, şiddet çağrıları, suçlamalar veya
                    insanlık dışı karşılaştırmalar içeren içerikler.
                  </li>
                  <li>
                    Korunan özelliklere dayalı bireylere veya gruplara zarar
                    veren içerikleri.
                  </li>
                </ul>
                Nefret İdeolojisi <br />
                Nefret ideolojileri, korunan özelliklerinden dolayı insanlara
                açık düşmanlık gösterir. Bunlar kapsayıcı ve destekleyici
                topluluğumuzla uyumsuzdur ve bu tür ideolojileri teşvik eden
                içerikleri kaldırırız.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Herhangi bir nefret ideolojisini öven, teşvik eden, yücelten
                    veya destekleyen içerikler.
                  </li>
                  <li>
                    Nefret ideolojileriyle ilgili içerikler, dahil olmak üzere
                    semboller, sloganlar, müzik veya diğer nesneler.
                  </li>
                  <li>
                    Korunan özelliklere sahip grupları etkileyen iyi belgelenmiş
                    şiddet olaylarını inkar eden veya üstünlük iddiaları içeren
                    içerikler.
                  </li>
                  <li>
                    Nefret ideolojilerini haklı çıkaran gerçek dışı teoriler.
                  </li>
                </ul>
                Aşırılık <br />
                TürkAfrica.Market'te şiddete karşıyız. Platformumuzun şiddeti
                tehdit etmek veya teşvik etmek için kullanılmasına izin
                vermiyoruz. Platformumuz dışında şiddeti teşvik eden hesapları
                askıya alabilir veya yasaklayabiliriz ve tehditleri yasal
                makamlara bildirebiliriz. Topluluğumuzu korumak için diğer
                platformlardan ve çevrimdışı bilgileri de dikkate alarak şiddet
                ve aşırılık yanlısı birey veya örgütleri tanımlayabiliriz. Bu
                tür varlıklarla ilişkilendirilen hesaplar kapatılacaktır.
              </p>
            </div>
            <div className="text_div">
              <h1>Şiddet ve Şiddeti Teşvik Etme Tehditleri</h1>
              <p>
                Şiddeti teşvik etme, şiddet işlemeye teşvik etmek veya
                başkalarını şiddet işlemeye cesaretlendirmek anlamına gelir.
                Ciddi fiziksel zarara yol açabilecek tehditlere veya şiddeti
                teşvik etmeye izin vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                Fiziksel şiddeti savunan ifadeler veya görseller. <br />
                Bireylere veya gruplara korku salmak veya tehdit etmek için
                silah getirme çağrıları. <br />
                Tehlikeli Bireyler ve Örgütler <br />
                Şiddetle ilişkili birey veya örgütlere platformumuzda yer
                vermiyoruz. Bu, toplu katilleri, nefret gruplarını, suç
                örgütlerini, terör örgütlerini ve diğerlerini içerir
              </p>
            </div>
            <div className="text_div">
              <h1>Terör Örgütleri ve Örgütlü Nefret</h1>
              <p>
                Terör örgütleri ve örgütlü nefret grupları, siyasi, dini, etnik
                veya ideolojik amaçlarla sivilleri hedef alır. Bu tür
                varlıklarla ilgili içerikleri kaldırırız.
              </p>
            </div>
            <div className="text_div">
              <h1>Örgütlü Suç</h1>
              <p>
                Ticaret veya finansal suçlar gibi ciddi suçlarla ilgili suç
                örgütleri kabul edilmez.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                Tehlikeli bireyleri veya örgütleri destekleyen içerikler. <br />
                Tehlikeli örgütler için işe alım veya tanıtım içerikleri. <br />
                Yasa Dışı Faaliyetler <br />
                TürkAfrica.Market'in yasaları veya düzenlemeleri ihlal eden
                faaliyetlere olanak sağlamamasını sağlamak için çalışıyoruz.
                <br />
                Suçla ilgili içeriklere izin vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Suç Faaliyetleri</h1>
              <p>
                Bu, hırsızlık, insan sömürüsü veya sahtecilik gibi geniş bir
                yasa dışı eylem yelpazesini içerir.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Fiziksel zarar, insan sömürüsü veya yasa dışı ticareti
                    teşvik eden içerikler.
                  </li>
                </ul>
                Dolandırıcılıklar ve Sahtekarlıklar <br />
                İnsanları yasa dışı mali veya kişisel kazanç için aldatan
                içerikleri kaldırırız, çeşitli dolandırıcılık türleri dahil.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Phishing, Ponzi şemaları veya diğer dolandırıcılık
                    faaliyetleriyle ilgili içerikler.
                  </li>
                </ul>
                Gizlilik, Kişisel Veriler ve PII <br />
                Kişisel verilerin gizliliğini veya kişisel olarak tanımlanabilir
                bilgileri (PII) ihlal eden içeriklere izin vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                Kişisel verileri veya PII içeren içerikler. <br />
                Şiddetli ve Grafik İçerik
                <br />
                Aşırı şok edici olan veya şiddeti yücelten içeriklere izin
                vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    İnsanlar veya hayvanlarla ilgili şiddetli veya grafik
                    olayları betimleyen içerikler.
                  </li>
                </ul>
                İntihar, Kendine Zarar Verme ve Tehlikeli Davranışlar
                <br /> Topluluk üyelerimizin sağlık ve esenliğine öncelik
                veriyoruz. İntihara, kendine zarar vermeye, yeme bozukluklarına
                veya tehlikeli faaliyetlere yol açabilecek içeriklere izin
                vermiyoruz.
              </p>
            </div>
            {/* <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content related to suicide, self-harm, eating disorders, or
                    dangerous acts.
                  </li>
                </ul>
                Harassment and Bullying <br />
                We strive for an inclusive community free from harassment and
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Abusive Behavior</h1>
              <p>
                We remove expressions of abuse, including threats or derogatory
                statements.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that insults, intimidates, or harasses individuals.
                  </li>
                </ul>
                Sexual Harassment <br /> Sexual harassment in any form is not
                tolerated.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that includes unwanted sexual behavior or
                    commentary.
                  </li>
                </ul>
                Threats of Hacking, Doxxing, and Blackmail <br />
                We consider online behaviors like doxxing and hacking as forms
                of abuse.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Threats to reveal personal data or engage in hacking or
                    blackmail.
                  </li>
                </ul>
                Adult Nudity and Sexual <br /> Activitie We prohibit pornography
                and sexually explicit content
              </p>
            </div>
            <div className="text_div">
              <h1>Sexual Exploitation</h1>
              <p>Sexually exploitative content is not permitted.</p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content depicting non-consensual sexual acts or nudity. <br />
                Integrity and Authenticity <br />
                We maintain the authenticity of our platform and prevent
                activities undermining its integrity.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam and Fake Engagement</h1>
              <p>We prohibit artificial manipulation of engagement metrics.</p>
            </div>
            <div className="text_div">
              <h1>Impersonation</h1>
              <p>
                We do not allow deceptive impersonation but permit parody or
                commentary accounts if clearly indicated.
              </p>
            </div>
            <div className="text_div">
              <h1>Misinformation</h1>
              <p>We do not permit harmful misinformation.</p>
            </div>
            <div className="text_div">
              <h1>Intellectual Property Violations</h1>
              <p>
                We respect intellectual property rights and remove content that
                infringes these rights. <br />
                General Commitments <br />
                <ul>
                  <li>
                    <h1>Safety:</h1>Commitment to making TurkAfrica.Market a
                    safe place.
                  </li>
                  <li>
                    <h1>Privacy:</h1>Protecting personal privacy and
                    information.
                  </li>
                  <li>
                    <h1>Dignity:</h1>Respecting the dignity and rights of all
                    people.
                  </li>
                </ul>
                These guidelines apply globally to all content and are designed
                to be comprehensive. We understand that context matters and
                strive to apply our policies consistently and fairly. Users can
                report violations, and we may notify law enforcement in cases of
                genuine risk.
              </p>
            </div> */}
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discrimination, Haine et Antisémitisme</h1>
              <p>
                TürkAfrica.Market est une plateforme inclusive avec une
                tolérance zéro pour la discrimination, la haine, le racisme et
                l'antisémitisme. Nous n'autorisons pas de contenu qui contient
                des discours haineux ou promeut un comportement haineux. Les
                comptes qui s'engagent dans des discours haineux ou associés à
                des discours haineux en dehors de notre plateforme seront
                suspendus ou bannis. Nous définissons les discours haineux ou
                comportements haineux comme des contenus qui attaquent,
                menacent, incitent à la violence contre, ou déshumanisent un
                individu ou un groupe sur la base d'attributs protégés : Caste,
                Handicap, Ethnicité, Genre, Origine nationale, Race, Religion,
                et Orientation sexuelle.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu haineux lié à un individu ou un groupe, y compris
                    des affirmations d'infériorité, des appels à la violence,
                    des accusations criminelles, ou des comparaisons
                    déshumanisantes.
                  </li>
                  <li>
                    Contenu représentant des dommages à des individus ou à des
                    groupes basés sur des attributs protégés.
                  </li>
                </ul>
                Idéologie Haineuse
                <br />
                Les idéologies haineuses montrent une hostilité claire envers
                les personnes en raison de leurs attributs protégés. Ces
                idéologies sont incompatibles avec notre communauté inclusive et
                solidaire, et nous supprimons le contenu les promouvant.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas:
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu faisant l'éloge, promouvant, glorifiant ou soutenant
                    une idéologie haineuse.
                  </li>
                  <li>
                    Contenu lié à des idéologies haineuses, y compris des
                    symboles, slogans, musiques, ou autres objets.
                  </li>
                  <li>
                    Contenu niant des événements violents bien documentés
                    affectant des groupes avec des attributs protégés ou faisant
                    des revendications de suprématie.
                  </li>
                  <li>
                    Théories non factuelles justifiant des idéologies haineuses.
                  </li>
                </ul>
                Extrémisme <br />
                Nous nous opposons fermement à la violence sur ou en dehors de
                TürkAfrica.Market. Nous n'autorisons pas notre plateforme à être
                utilisée pour menacer ou inciter à la violence ou pour
                promouvoir des individus ou des organisations dangereux. Nous
                pouvons suspendre ou bannir des comptes promouvant la violence
                hors de la plateforme et signaler des menaces aux autorités
                légales. Pour protéger notre communauté, nous pouvons considérer
                des informations provenant d'autres plateformes et hors ligne
                pour identifier des individus ou des organisations violents et
                extrémistes. Les comptes trouvés associés à de telles entités
                seront fermés.
              </p>
            </div>
            <div className="text_div">
              <h1>Menaces et Incitation à la Violence</h1>
              <p>
                L'incitation à la violence comprend le plaidoyer ou
                l'encouragement d'autres à commettre des violences. Nous
                n'autorisons pas les menaces ou l'incitation à la violence qui
                peuvent conduire à de graves dommages physiques.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Déclarations ou imageries prônant la violence physique. <br />
                Appels à apporter des armes pour intimider ou menacer des
                individus ou des groupes. <br />
                Individus et Organisations Dangereux <br />
                Nous n'autorisons pas les individus ou organisations sur notre
                plateforme qui sont impliqués dans la violence. Cela inclut les
                meurtriers de masse, les groupes haineux, les organisations
                criminelles, les organisations terroristes, et d'autres.
              </p>
            </div>
            <div className="text_div">
              <h1>Organisations Terroristes et Haine Organisée</h1>
              <p>
                Les organisations terroristes et les groupes de haine organisée
                ciblent des civils pour des objectifs politiques, religieux,
                ethniques ou idéologiques. Nous supprimons le contenu lié à de
                telles entités.
              </p>
            </div>
            <div className="text_div">
              <h1>Crime Organisé</h1>
              <p>
                Les organisations criminelles impliquées dans des crimes graves
                tels que le trafic ou les crimes financiers ne sont pas
                autorisées.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Contenu soutenant des individus ou des organisations dangereux.{" "}
                <br />
                Contenu de recrutement ou promotionnel pour des organisations
                dangereuses. <br />
                Activités Illégales <br />
                Nous travaillons pour garantir que TürkAfrica.Market n'active
                pas des activités qui violent les lois ou les réglementations.{" "}
                <br />
                Nous interdisons le contenu lié à des activités criminelles.
              </p>
            </div>
            <div className="text_div">
              <h1>Activités Criminelles</h1>
              <p>
                Cela inclut une large gamme d'actes illégaux, tels que le vol,
                l'exploitation humaine, ou la contrefaçon.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu promouvant des dommages physiques, l'exploitation
                    humaine, ou le commerce illégal.
                  </li>
                </ul>
                Fraudes et Escroqueries <br />
                Nous supprimons le contenu trompant les gens pour un gain
                financier ou personnel illégal, y compris divers types
                d'escroqueries.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu lié au phishing, aux schémas de Ponzi, ou à d'autres
                    activités frauduleuses..
                  </li>
                </ul>
                Vie Privée, Données Personnelles et PII
                <br />
                Nous n'autorisons pas le contenu qui viole la confidentialité
                des données personnelles ou des informations personnellement
                identifiables (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Contenu contenant des données personnelles ou des PII <br />
                Contenu Violent et Graphique <br />
                Nous n'autorisons pas le contenu qui est excessivement choquant
                ou qui glorifie la violence.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu dépeignant des événements violents ou graphiques
                    impliquant des humains ou des animaux.
                  </li>
                </ul>
                Suicide, Automutilation et Actes Dangereux <br /> Nous
                priorisons la santé et le bien-être de nos membres de la
                communauté. Nous n'autorisons pas le contenu pouvant conduire au
                suicide, à l'automutilation, aux troubles alimentaires, ou à des
                activités dangereuses.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu lié au suicide, à l'automutilation, aux troubles
                    alimentaires, ou à des actes dangereux.
                  </li>
                </ul>
                Harcèlement et Intimidation <br />
                Nous nous efforçons d'une communauté inclusive exempte de
                harcèlement et d'intimidation.
              </p>
            </div>
            <div className="text_div">
              <h1>Comportement Abusif</h1>
              <p>
                Nous supprimons les expressions d'abus, y compris les menaces ou
                déclarations péjoratives.
              </p>
            </div>
            <div className="text_div">
              <h1>
                {" "}
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu qui insulte, intimide ou harcèle des individus.
                  </li>
                </ul>
                Harcèlement Sexuel <br /> Le harcèlement sexuel sous toute forme
                n'est pas toléré.
              </p>
            </div>
            <div className="text_div">
              <h1>
                {" "}
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    • Contenu qui inclut un comportement sexuel non désiré ou
                    des commentaires.
                  </li>
                </ul>
                Menaces de Piratage, Doxxing et Chantage <br />
                Nous considérons les comportements en ligne comme le doxxing et
                le piratage comme des formes d'abus.
              </p>
            </div>
            <div className="text_div">
              <h1>
                {" "}
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Menaces de révéler des données personnelles ou de s'engager
                    dans le piratage ou le chantage
                  </li>
                </ul>
                Nudité Adulte et Activités Sexuelles <br /> Nous interdisons la
                pornographie et le contenu sexuellement explicite
              </p>
            </div>
            <div className="text_div">
              <h1>Exploitation Sexuelle</h1>
              <p>Le contenu sexuellement exploiteur n'est pas autorisé.</p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Contenu dépeignant des actes sexuels non consensuels ou de la
                nudité. <br />
                Intégrité et Authenticité
                <br />
                Nous maintenons l'authenticité de notre plateforme et prévenons
                les activités compromettant son intégrité.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam and Fake Engagement</h1>
              <p>We prohibit artificial manipulation of engagement metrics.</p>
            </div>
            <div className="text_div">
              <h1>Spam et Faux Engagement</h1>
              <p>
                Nous interdisons la manipulation artificielle des métriques
                d’engagement.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam et Engagement Factice</h1>
              <p>
                Nous interdisons toute manipulation artificielle des mesures
                d'engagement.
              </p>
            </div>
            <div className="text_div">
              <h1>Violations de la Propriété Intellectuelle</h1>
              <p>
                Nous respectons les droits de propriété intellectuelle et
                supprimons les contenus qui enfreignent ces droits. <br />
                Engagements Généraux
                <br />
                <ul>
                  <li>
                    <h1>Sécurité:</h1> Engagement à faire de TurkAfrica.Market
                    un lieu sûr.
                  </li>
                  <li>
                    <h1>Vie Privée : </h1>Protection de la vie privée
                    personnelle et des informations.
                  </li>
                  <li>
                    <h1>Dignité:</h1> Respect de la dignité et des droits de
                    toutes les personnes.
                  </li>
                </ul>
                Ces directives s'appliquent globalement à tous les contenus et
                sont conçues pour être complètes. Nous comprenons que le
                contexte est important et nous nous efforçons d'appliquer nos
                politiques de manière cohérente et équitable. Les utilisateurs
                peuvent signaler les violations, et nous pouvons notifier les
                autorités judiciaires en cas de risque réel.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discriminação, Ódio e Antissemitismo</h1>
              <p>
                A TürkAfrica.Market é uma plataforma inclusiva com tolerância
                zero para discriminação, ódio, racismo e antissemitismo. Não
                permitimos conteúdo que contenha discurso de ódio ou promova
                comportamento odioso. Contas envolvidas em discurso de ódio ou
                associadas a discurso de ódio fora de nossa plataforma serão
                suspensas ou banidas. <br />
                Definimos discurso de ódio ou comportamento odioso como conteúdo
                que ataca, ameaça, incita violência contra ou desumaniza um
                indivíduo ou grupo com base em atributos protegidos: Casta,
                Deficiência, Etnia, Gênero, Origem Nacional, Raça, Religião e
                Orientação Sexual.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo odioso relacionado a um indivíduo ou grupo,
                    incluindo afirmações de inferioridade, apelos à violência,
                    acusações criminais ou comparações desumanizantes.
                  </li>
                  <li>
                    Conteúdo retratando danos a indivíduos ou grupos com base em
                    atributos protegidos.
                  </li>
                </ul>
                Ideologia de Ódio
                <br />
                Ideologias de ódio demonstram clara hostilidade contra pessoas
                devido a seus atributos protegidos. Estas são incompatíveis com
                nossa comunidade inclusiva e solidária, e removemos conteúdo que
                promova tais ideologias.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo elogiando, promovendo, glorificando ou apoiando
                    qualquer ideologia de ódio.
                  </li>
                  <li>
                    Conteúdo relacionado a ideologias de ódio, incluindo
                    símbolos, slogans, músicas ou outros objetos.
                  </li>
                  <li>
                    Conteúdo negando eventos violentos bem documentados que
                    afetam grupos com atributos protegidos ou fazendo
                    reivindicações de supremacia.
                  </li>
                  <li>Teorias não factuais justificando ideologias de ódio.</li>
                </ul>
                Extremismo <br />
                Somos firmemente contra a violência dentro ou fora da
                TürkAfrica.Market. Não permitimos que nossa plataforma seja
                usada para ameaçar ou incitar violência ou para promover
                indivíduos ou organizações perigosas. Podemos suspender ou banir
                contas promovendo violência fora da plataforma e relatar ameaças
                às autoridades legais. Para proteger nossa comunidade, podemos
                considerar informações de outras plataformas e offline na
                identificação de indivíduos ou organizações violentos e
                extremistas. Contas associadas a tais entidades serão fechadas.
              </p>
            </div>
            <div className="text_div">
              <h1>Ameaças e Incitação à Violência</h1>
              <p>
                Incitação à violência inclui defender ou encorajar outros a
                cometer violência. Não permitimos ameaças ou incitação à
                violência que possam levar a danos físicos graves.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Declarações ou imagens defendendo violência física. <br />
                Chamados para trazer armas para intimidar ou ameaçar indivíduos
                ou grupos.
                <br />
                Indivíduos e Organizações Perigosas
                <br />
                Não permitimos indivíduos ou organizações em nossa plataforma
                que estejam envolvidos em violência. Isso inclui assassinos em
                massa, grupos de ódio, organizações criminosas, organizações
                terroristas e outros.
              </p>
            </div>
            <div className="text_div">
              <h1>Organizações Terroristas e Ódio Organizado</h1>
              <p>
                Organizações terroristas e grupos de ódio organizado visam civis
                por objetivos políticos, religiosos, étnicos ou ideológicos.
                Removemos conteúdo relacionado a tais entidades.
              </p>
            </div>
            <div className="text_div">
              <h1>Crime Organizado</h1>
              <p>
                Organizações criminosas envolvidas em crimes graves como tráfico
                ou crimes financeiros não são permitidas.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Conteúdo apoiando indivíduos ou organizações perigosas.
                <br />
                Conteúdo de recrutamento ou promocional para organizações
                perigosas.
                <br />
                Atividades Ilegais <br />
                Trabalhamos para garantir que a TürkAfrica.Market não habilite
                atividades que violem leis ou regulamentos. <br />
                Proibimos conteúdo relacionado a atividades criminosas.
              </p>
            </div>
            <div className="text_div">
              <h1>Atividades Criminais</h1>
              <p>
                Isso inclui uma ampla gama de atos ilegais, como roubo,
                exploração humana ou falsificação.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo promovendo danos físicos, exploração humana ou
                    comércio ilegal.
                  </li>
                </ul>
                Fraudes e Golpes
                <br />
                Removemos conteúdo enganando pessoas para ganhos financeiros ou
                pessoais ilegais, incluindo vários tipos de golpes.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo relacionado a phishing, esquemas de Ponzi ou outras
                    atividades fraudulentas.
                  </li>
                </ul>
                Privacidade, Dados Pessoais e PII <br />
                Não permitimos conteúdo que viole a confidencialidade de dados
                pessoais ou informações pessoalmente identificáveis (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Conteúdo contendo dados pessoais ou PII. <br />
                Conteúdo Violento e Gráfico
                <br />
                Não permitimos conteúdo excessivamente chocante ou que
                glorifique violência.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo retratando eventos violentos ou gráficos envolvendo
                    humanos ou animais.
                  </li>
                </ul>
                Suicídio, Automutilação e Atos Perigosos
                <br /> Priorizamos a saúde e o bem-estar dos membros de nossa
                comunidade. Não permitimos conteúdo que possa levar ao suicídio,
                automutilação, distúrbios alimentares ou atividades perigosas.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo relacionado a suicídio, automutilação, transtornos
                    alimentares ou atos perigosos.
                  </li>
                </ul>
                Assédio e Bullying
                <br />
                Nos esforçamos por uma comunidade inclusiva livre de assédio e
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Comportamento Abusivo</h1>
              <p>
                Removemos expressões de abuso, incluindo ameaças ou declarações
                depreciativas.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>Conteúdo que insulte, intimide ou assedie indivíduos.</li>
                </ul>
                Assédio Sexual <br /> Assédio sexual em qualquer forma não é
                tolerado.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo que inclua comportamento sexual indesejado ou
                    comentários.
                  </li>
                </ul>
                Ameaças de Hacking, Doxxing e Chantagem
                <br />
                Consideramos comportamentos online como doxxing e hacking como
                formas de abuso.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Ameaças de revelar dados pessoais ou se envolver em hacking
                    ou chantagem.
                  </li>
                </ul>
                Nudez Adulta e Atividades Sexuais <br /> Proibimos pornografia e
                conteúdo sexualmente explícito.
              </p>
            </div>
            <div className="text_div">
              <h1>Exploração Sexual</h1>
              <p>Conteúdo sexualmente exploratório não é permitido.</p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Conteúdo retratando atos sexuais não consensuais ou nudez.{" "}
                <br />
                Integridade e Autenticidade <br />
                Mantemos a autenticidade de nossa plataforma e prevenimos
                atividades que prejudiquem sua integridade.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam e Engajamento Falso</h1>
              <p>
                Proibimos a manipulação artificial de métricas de engajamento.
              </p>
            </div>
            <div className="text_div">
              <h1>Personificação</h1>
              <p>
                Não permitimos personificação enganosa, mas permitimos contas de
                paródia ou comentário se claramente indicadas.
              </p>
            </div>
            <div className="text_div">
              <h1>Desinformação</h1>
              <p>WNão permitimos desinformação prejudicial.</p>
            </div>
            <div className="text_div">
              <h1>Violações de Propriedade Intelectual</h1>
              <p>
                Respeitamos os direitos de propriedade intelectual e removemos
                conteúdo que infrinja esses direitos. <br />
                Compromissos Gerais <br />
                <ul>
                  <li>
                    <h1> Segurança :</h1> Compromisso em tornar a
                    TürkAfrica.Market um lugar seguro
                  </li>
                  <li>
                    <h1>Privacidade :</h1> Proteção da privacidade pessoal e
                    informação.
                  </li>
                  <li>
                    <h1>Dignidade:</h1> Respeito à dignidade e direitos de todas
                    as pessoas.
                  </li>
                </ul>
                Estas diretrizes aplicam-se globalmente a todo o conteúdo e são
                projetadas para serem abrangentes. Entendemos que o contexto
                importa e nos esforçamos para aplicar nossas políticas de
                maneira consistente e justa. Os usuários podem denunciar
                violações, e podemos notificar as autoridades policiais em casos
                de risco genuíno.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>Ubaguzi, Chuki, na Uhasama dhidi ya Wayahudi</h1>
              <p>
                TürkAfrica.Market ni jukwaa lenye kujumuisha wote na halivumilii
                ubaguzi, chuki, ubaguzi wa rangi, na uhasama dhidi ya Wayahudi.
                Hatukubali yaliyomo yanayojumuisha hotuba ya chuki au
                yanayotangaza tabia ya chuki. Akaunti zinazojihusisha na hotuba
                ya chuki au zinazohusiana na hotuba ya chuki nje ya jukwaa letu
                zitasimamishwa au kufungiwa. <br />
                Tunafafanua hotuba ya chuki au tabia ya chuki kama yaliyomo
                yanayoshambulia, kutishia, kuchochea vurugu dhidi ya, au
                vinginevyo kudhalilisha mtu binafsi au kikundi kulingana na sifa
                zilindwa: Tabaka, Ulemavu, Uasili wa Kikabila, Jinsia, Asili ya
                Kitaifa, Rangi, Dini, na Mwelekeo wa Kingono.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yenye chuki yanayohusiana na mtu binafsi au
                    kikundi, ikiwa ni pamoja na madai ya uduni, wito wa vurugu,
                    tuhuma za uhalifu, au kulinganisha kudhalilisha.
                  </li>
                  <li>
                    Yaliyomo yanayoonyesha madhara kwa watu binafsi au vikundi
                    kulingana na sifa zilindwa.
                  </li>
                </ul>
                Itikadi ya Chuki <br />
                Itikadi za chuki zinaonyesha uadui wazi kwa watu kwa sababu ya
                sifa zao zilindwa. Hizi hazikubaliani na jamii yetu
                inayojumuisha na kusaidiana, na tunaondoa yaliyomo yanayotangaza
                zao.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayosifu, kutangaza, kufafanua, au kusaidia
                    itikadi yoyote ya chuki.
                  </li>
                  <li>
                    Yaliyomo yanayohusiana na itikadi za chuki, ikiwa ni pamoja
                    na alama, kauli mbiu, muziki, au vitu vingine.
                  </li>
                  <li>
                    Yaliyomo yanayokana matukio ya vurugu yaliyothibitishwa
                    vizuri yanayoathiri vikundi vyenye sifa zilindwa au madai ya
                    kufanya ubora.
                  </li>
                  <li>
                    Nadharia zisizo za kweli zinazohalalisha itikadi za chuki.
                  </li>
                </ul>
                Usaidi <br />
                Tunapinga vikali vurugu ndani au nje ya TürkAfrica.Market.
                Hatuwezi kuruhusu jukwaa letu litumike kuwatishia au kuchochea
                vurugu au kutangaza watu au mashirika hatari. Tunaweza
                kusimamisha au kufungia akaunti zinazotangaza vurugu nje ya
                jukwaa na kuripoti vitisho kwa mamlaka za kisheria. Kulinda
                jamii yetu, tunaweza kuzingatia taarifa kutoka kwa majukwaa
                mengine na nje ya mtandao katika kutambua watu na mashirika ya
                vurugu na ya kigaidi. Akaunti zinazopatikana zinahusishwa na
                vyombo kama hivyo zitafungwa.
              </p>
            </div>
            <div className="text_div">
              <h1>Vitisho na Kuchochea Vurugu</h1>
              <p>
                Kuchochea vurugu kunajumuisha kutetea au kuhamasisha wengine
                kutekeleza vurugu. Hatuwezi kuruhusu vitisho au kuchochea vurugu
                ambazo zinaweza kusababisha madhara makubwa ya kimwili.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Kauli au picha zinazotetea vurugu za kimwili. <br />
                Wito wa kuleta silaha kwa ajili ya kutisha au kutishia watu
                binafsi au vikundi. <br />
                Watu na Mashirika Hatari <br />
                Haturuhusu watu au mashirika kwenye jukwaa letu ambao wanahusika
                katika vurugu. Hii inajumuisha wauaji wa umati, makundi ya
                chuki, mashirika ya uhalifu, mashirika ya kigaidi, na wengine.
              </p>
            </div>
            <div className="text_div">
              <h1>Mashirika ya Kigaidi na Chuki Iliyoandaliwa</h1>
              <p>
                Mashirika ya kigaidi na makundi ya chuki yaliyoandaliwa
                yanawalenga raia kwa malengo ya kisiasa, kidini, kikabila, au
                kiitikadi. Tunaondoa yaliyomo yanayohusiana na vyombo kama
                hivyo.
              </p>
            </div>
            <div className="text_div">
              <h1>Uhalifu Uliyoandaliwa</h1>
              <p>
                Mashirika ya uhalifu yanayohusika katika uhalifu mkubwa kama
                usafirishaji haramu au uhalifu wa kifedha hayaruhusiwi.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Yaliyomo yanayounga mkono watu hatari au mashirika. <br />
                Yaliyomo ya kuajiri au kutangaza mashirika hatari. <br />
                Shughuli Haramu <br />
                Tunafanya kazi kuhakikisha TürkAfrica.Market haitoi nafasi kwa
                shughuli zinazokiuka sheria au kanuni. <br />
                Tunakataza yaliyomo yanayohusiana na shughuli za uhalifu.
              </p>
            </div>
            <div className="text_div">
              <h1>Shughuli za Uhalifu</h1>
              <p>
                Hii inajumuisha aina mbalimbali za vitendo haramu, kama wizi,
                unyonyaji wa binadamu, au uuzaji bandia.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayotangaza madhara ya kimwili, unyonyaji wa
                    binadamu, au biashara haramu.
                  </li>
                </ul>
                Utapeli na Udanganyifu <br />
                Tunaondoa yaliyomo yanayodanganya watu kwa faida isiyo halali ya
                kifedha au binafsi, ikiwa ni pamoja na aina mbalimbali za
                utapeli.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayohusiana na utapeli, mipango ya Ponzi, au
                    shughuli nyingine za udanganyifu.
                  </li>
                </ul>
                Faragha, Data Binafsi, na PII
                <br />
                Haturuhusu yaliyomo yanayokiuka usiri wa data binafsi au taarifa
                binafsi zinazoweza kutambulika (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Yaliyomo yanayojumuisha data binafsi au PII. <br />
                Yaliyomo ya Vurugu na ya Kushtur
                <br />
                Haturuhusu yaliyomo yanayoshtua kupita kiasi au yanayotukuza
                vurugu.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayoonyesha matukio ya vurugu au ya kushtua
                    yanayohusisha binadamu au wanyama.
                  </li>
                </ul>
                Kujiua, Kujidhuru, na Vitendo Hatari
                <br /> Tunapewa kipaumbele afya na ustawi wa wanajamii wetu.
                Hatuwezi kuruhusu yaliyomo yanayoweza kusababisha kujiua,
                kujidhuru, matatizo ya kula, au shughuli hatari.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayohusiana na kujiua, kujiumiza, matatizo ya
                    ulaji, au vitendo hatari.
                  </li>
                </ul>
                Unyanyasaji na Uonevu <br />
                Tunajitahidi kwa jamii inayojumuisha na isiyo na unyanyasaji na
                uonevu.
              </p>
            </div>
            <div className="text_div">
              <h1>Tabia ya Unyanyasaji</h1>
              <p>
                Tunaondoa matamshi ya unyanyasaji, ikiwa ni pamoja na vitisho au
                kauli za kudharau.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>Yaliyomo yanayotukana, kubagua, au kunyanyasa watu.</li>
                </ul>
                Unyanyasaji wa Kingono
                <br />
                Unyanyasaji wa kingono kwa namna yoyote haukubaliki.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayojumuisha tabia za kingono zisizotakiwa au
                    maoni.
                  </li>
                </ul>
                Vitisho vya Udukuzi, Doxxing, na Ulaghai <br />
                Tunaona tabia za mtandaoni kama doxxing na udukuzi kama aina za
                unyanyasaji.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Vitisho vya kufichua data binafsi au kujihusisha na udukuzi
                    au ulaghai.
                  </li>
                </ul>
                Uchi wa Watu Wazima na Shughuli za Kingono
                <br /> Tunakataza ponografia na maudhui ya kingono waziwazi.
              </p>
            </div>
            <div className="text_div">
              <h1>Unyonyaji wa Kingono</h1>
              <p>Maudhui yanayoonyesha unyonyaji wa kingono hayaruhusiwi.</p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Yaliyomo yanayoonyesha vitendo vya kingono visivyo na ridhaa au
                uchi. <br />
                Uaminifu na Uhalisia <br />
                Tunadumisha uhalisia wa jukwaa letu na kuzuia shughuli
                zinazoharibu uaminifu wake.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam na Ushiriki wa Bandia</h1>
              <p>Tunakataza uchochezi bandia wa metriki za ushiriki.</p>
            </div>
            <div className="text_div">
              <h1>Kuigiza</h1>
              <p>
                Haturuhusu udanganyifu wa kuigiza lakini tunaruhusu akaunti za
                dhihaka au maoni ikiwa zimewekwa wazi.
              </p>
            </div>
            <div className="text_div">
              <h1>Upotoshaji</h1>
              <p>Hatukubali upotoshaji wenye madhara.</p>
            </div>
            <div className="text_div">
              <h1>Ukiukaji wa Haki Miliki</h1>
              <p>
                Tunaheshimu haki miliki na kuondoa yaliyomo yanayokiuka haki
                hizo.
                <br />
                Ahadi za Jumla
                <br />
                <ul>
                  <li>
                    <h1>Usalama:</h1> Ahadi ya kufanya TurkAfrica.Market kuwa
                    mahali salama.
                  </li>
                  <li>
                    <h1>Faragha:</h1> Kulinda faragha binafsi na taarifa.
                  </li>
                  <li>
                    <h1>Heshima:</h1> Kuheshimu utu na haki za watu wote.
                  </li>
                </ul>
                Miongozo hii inatumika kimataifa kwa yaliyomo yote na imeundwa
                kuwa kamili. Tunaelewa umuhimu wa muktadha na tunajitahidi
                kutumia sera zetu kwa usawa na haki. Watumiaji wanaweza kuripoti
                ukiukaji, na tunaweza kuarifu mamlaka za kisheria katika kesi za
                hatari halisi.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discriminación, Odio y Antisemitismo</h1>
              <p>
                TürkAfrica.Market es una plataforma inclusiva con tolerancia
                cero hacia la discriminación, el odio, el racismo y el
                antisemitismo. No permitimos contenido que contenga discursos de
                odio o promueva comportamientos odiosos. Las cuentas que
                participen en discursos de odio o estén asociadas con discursos
                de odio fuera de nuestra plataforma serán suspendidas o
                prohibidas. <br />
                Definimos el discurso de odio o comportamiento odioso como
                contenido que ataca, amenaza, incita a la violencia contra, o de
                otro modo deshumaniza a un individuo o un grupo basado en
                atributos protegidos: Casta, Discapacidad, Etnia, Género, Origen
                nacional, Raza, Religión y Orientación sexual.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido odioso relacionado con un individuo o grupo,
                    incluyendo afirmaciones de inferioridad, llamados a la
                    violencia, acusaciones criminales o comparaciones
                    deshumanizantes.
                  </li>
                  <li>
                    Contenido que represente daño a individuos o grupos basado
                    en atributos protegidos.
                  </li>
                </ul>
                Ideología de Odio
                <br />
                Las ideologías de odio muestran una clara hostilidad hacia las
                personas debido a sus atributos protegidos. Estas son
                incompatibles con nuestra comunidad inclusiva y de apoyo, y
                eliminamos contenido que las promueva.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que alabe, promueva, glorifique o apoye cualquier
                    ideología de odio.
                  </li>
                  <li>
                    Contenido relacionado con ideologías de odio, incluyendo
                    símbolos, consignas, música u otros objetos.
                  </li>
                  <li>
                    Contenido que niegue eventos violentos bien documentados que
                    afecten a grupos con atributos protegidos o haga
                    afirmaciones de supremacía.
                  </li>
                  <li>
                    Teorías no factuales que justifiquen ideologías de odio..
                  </li>
                </ul>
                Extremismo <br />
                Nos oponemos firmemente a la violencia dentro y fuera de
                TürkAfrica.Market. No permitimos que nuestra plataforma se
                utilice para amenazar o incitar a la violencia, ni para promover
                individuos u organizaciones peligrosas. Podemos suspender o
                prohibir cuentas que promuevan violencia fuera de la plataforma
                e informar amenazas a las autoridades legales. Para proteger
                nuestra comunidad, podemos considerar información de otras
                plataformas y fuera de línea para identificar individuos u
                organizaciones violentas y extremistas. Las cuentas asociadas
                con tales entidades serán cerradas.
              </p>
            </div>
            <div className="text_div">
              <h1>Amenazas e Incitación a la Violencia</h1>
              <p>
                La incitación a la violencia incluye abogar o alentar a otros a
                cometer violencia. No permitimos amenazas o incitación a la
                violencia que puedan conducir a daños físicos graves.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Declaraciones o imágenes que aboguen por la violencia física.{" "}
                <br />
                Llamados a traer armas para intimidar o amenazar a individuos o
                grupos. <br />
                Individuos y Organizaciones Peligrosas <br />
                No permitimos individuos u organizaciones en nuestra plataforma
                que estén involucrados en violencia. Esto incluye a asesinos
                masivos, grupos de odio, organizaciones criminales,
                organizaciones terroristas y otros.
              </p>
            </div>
            <div className="text_div">
              <h1>Organizaciones Terroristas y Odio Organizado</h1>
              <p>
                Las organizaciones terroristas y los grupos de odio organizado
                atacan a civiles por objetivos políticos, religiosos, étnicos o
                ideológicos. Eliminamos contenido relacionado con tales
                entidades.
              </p>
            </div>
            <div className="text_div">
              <h1>Crimen Organizado</h1>
              <p>
                Las organizaciones criminales involucradas en crímenes graves
                como tráfico o delitos financieros no están permitidas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Contenido que apoye a individuos u organizaciones peligrosas.{" "}
                <br />
                Contenido de reclutamiento o promocional para organizaciones
                peligrosas. <br />
                Actividades Ilegales <br />
                Trabajamos para asegurar que TürkAfrica.Market no permita
                actividades que violen leyes o regulaciones. <br />
                Prohibimos contenido relacionado con actividades criminales.
              </p>
            </div>
            <div className="text_div">
              <h1>Actividades Criminales</h1>
              <p>
                Esto incluye una amplia gama de actos ilegales, como robo,
                explotación humana o falsificación.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que promueva daño físico, explotación humana o
                    comercio ilegal.
                  </li>
                </ul>
                Fraudes y Estafas <br />
                Eliminamos contenido que engañe a las personas para obtener
                ganancias financieras o personales ilegales, incluyendo varios
                tipos de estafas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido relacionado con phishing, esquemas Ponzi u otras
                    actividades fraudulentas.
                  </li>
                </ul>
                Privacidad, Datos Personales y PII <br />
                No permitimos contenido que viole la confidencialidad de datos
                personales o información personal identificable (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Contenido que contenga datos personales o PII. <br />
                Contenido Violento y Gráfico <br />
                No permitimos contenido que sea excesivamente impactante o que
                glorifique la violencia.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que represente eventos violentos o gráficos que
                    involucren a humanos o animales.
                  </li>
                </ul>
                Suicidio, Autolesiones y Actos Peligrosos
                <br /> Priorizamos la salud y el bienestar de los miembros de
                nuestra comunidad. No permitimos contenido que pueda conducir al
                suicidio, autolesiones, trastornos alimentarios o actividades
                peligrosas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido relacionado con el suicidio, autolesión,
                    trastornos alimentarios o actos peligrosos.
                  </li>
                </ul>
                Acoso y Bullying
                <br />
                Nos esforzamos por una comunidad inclusiva libre de acoso y
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Comportamiento Abusivo</h1>
              <p>
                Eliminamos expresiones de abuso, incluyendo amenazas o
                declaraciones despectivas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>Contenido que insulte, intimide o acose a individuos.</li>
                </ul>
                Acoso Sexual <br /> No se tolera el acoso sexual en ninguna de
                sus formas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que incluya comportamiento sexual no deseado o
                    comentarios.
                  </li>
                </ul>
                Amenazas de Hacking, Doxxing y Chantaje <br />
                Consideramos comportamientos en línea como doxxing y hacking
                como formas de abuso.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    • Amenazas para revelar datos personales o participar en
                    hacking o chantaje.
                  </li>
                </ul>
                Nudez de Adultos y Actividades Sexuales
                <br /> Prohibimos la pornografía y el contenido sexualmente
                explícito.
              </p>
            </div>
            <div className="text_div">
              <h1>Explotación Sexual</h1>
              <p>SNo se permite contenido sexualmente explotador.</p>
            </div>

            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Contenido que represente actos sexuales no consensuales o
                desnudez. <br />
                Integridad y Autenticidad <br />
                Mantenemos la autenticidad de nuestra plataforma y prevenimos
                actividades que socaven su integridad.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam y Participación Falsa</h1>
              <p>
                Prohibimos la manipulación artificial de métricas de
                participación.
              </p>
            </div>
            <div className="text_div">
              <h1>Suplantación de Identidad</h1>
              <p>
                No permitimos la suplantación de identidad engañosa, pero
                permitimos cuentas de parodia o comentario si están claramente
                indicadas.
              </p>
            </div>
            <div className="text_div">
              <h1>Desinformación</h1>
              <p>We do not permit harmful misinformation.</p>
            </div>
            <div className="text_div">
              <h1>No permitimos desinformación dañina.</h1>
              <p>
                Violaciones de la Propiedad Intelectual Respetamos los derechos
                de propiedad intelectual y eliminamos contenido que infrinja
                estos derechos.
                <br />
                Compromisos Generales
                <ul>
                  <li>
                    <h1>Seguridad:</h1> Compromiso de hacer de TurkAfrica.Market
                    un lugar seguro.
                  </li>
                  <li>
                    <h1>Privacidad:</h1> Protección de la privacidad personal e
                    información.
                  </li>
                  <li>
                    <h1>Dignidad:</h1> Respeto a la dignidad y derechos de todas
                    las personas.
                  </li>
                </ul>
                Estas guías se aplican globalmente a todo el contenido y están
                diseñadas para ser integrales. Entendemos que el contexto
                importa y nos esforzamos por aplicar nuestras políticas de
                manera consistente y justa. Los usuarios pueden reportar
                violaciones, y podemos notificar a las autoridades legales en
                casos de riesgo genuino.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discrimination, Hate, and Anti-Semitism</h1>
              <p>
                TurkAfrica.Market is an inclusive platform with zero tolerance
                for discrimination, hate, racism, and anti-Semitism. We do not
                permit content that contains hate speech or promotes hateful
                behavior. Accounts engaging in hate speech or associated with
                hate speech off our platform will be suspended or banned. <br />
                We define hate speech or hateful behavior as content that
                attacks, threatens, incites violence against, or otherwise
                dehumanizes an individual or a group based on protected
                attributes: Caste, Disability, Ethnicity, Gender, National
                origin, Race, Religion, and Sexual orientation.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Hateful content related to an individual or group, including
                    claims of inferiority, calls for violence, criminal
                    accusations, or dehumanizing comparisons.
                  </li>
                  <li>
                    Content depicting harm to individuals or groups based on
                    protected attributes.
                  </li>
                </ul>
                Hateful Ideology <br />
                Hateful ideologies show clear hostility toward people because of
                their protected attributes. These are incompatible with our
                inclusive and supportive community, and we remove content
                promoting them.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content praising, promoting, glorifying, or supporting any
                    hateful ideology.
                  </li>
                  <li>
                    Content related to hateful ideologies, including symbols,
                    slogans, music, or other objects.
                  </li>
                  <li>
                    Content denying well-documented violent events affecting
                    groups with protected attributes or making supremacy claims.
                  </li>
                  <li>Non-factual theories justifying hateful ideologies.</li>
                </ul>
                Extremism <br />
                We firmly oppose violence on or off TurkAfrica.Market. We do not
                allow our platform to be used to threaten or incite violence or
                to promote dangerous individuals or organizations. We may
                suspend or ban accounts promoting off-platform violence and
                report threats to legal authorities. To protect our community,
                we may consider information from other platforms and offline in
                identifying violent and extremist individuals or organizations.
                Accounts found to be associated with such entities will be
                closed.
              </p>
            </div>
            <div className="text_div">
              <h1>Threats and Incitement to Violence</h1>
              <p>
                Incitement to violence includes advocating for or encouraging
                others to commit violence. We do not allow threats or incitement
                to violence that may lead to serious physical harm.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Statements or imagery advocating physical violence. <br />
                Calls to bring weapons to intimidate or threaten individuals or
                groups. <br />
                Dangerous Individuals and Organizations <br />
                We do not allow individuals or organizations on our platform who
                are involved in violence. This includes mass murderers, hate
                groups, criminal organizations, terrorist organizations, and
                others.
              </p>
            </div>
            <div className="text_div">
              <h1>Terrorist Organizations and Organised Hate</h1>
              <p>
                Terrorist organizations and organized hate groups target
                civilians for political, religious, ethnic, or ideological
                objectives. We remove content related to such entities.
              </p>
            </div>
            <div className="text_div">
              <h1>Organised Crime</h1>
              <p>
                Criminal organizations involved in serious crimes like
                trafficking or financial crimes are not permitted.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content supporting dangerous individuals or organizations.{" "}
                <br />
                Recruitment or promotional content for dangerous organizations.{" "}
                <br />
                Illegal Activities <br />
                We work to ensure TurkAfrica.Market does not enable activities
                that violate laws or regulations. <br />
                We prohibit content related to criminal activities.
              </p>
            </div>
            <div className="text_div">
              <h1>Criminal Activities</h1>
              <p>
                This includes a wide range of unlawful acts, such as theft,
                human exploitation, or counterfeiting.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content promoting physical harm, human exploitation, or
                    illegal trade.
                  </li>
                </ul>
                Frauds and Scams <br />
                We remove content deceiving people for unlawful financial or
                personal gain, including various types of scams.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content related to phishing, Ponzi schemes, or other
                    fraudulent activities.
                  </li>
                </ul>
                Privacy, Personal Data, and PII <br />
                We do not allow content that violates the confidentiality of
                personal data or personally identifiable information (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content containing personal data or PII. <br />
                Violent and Graphic Content <br />
                We do not permit content that is excessively shocking or that
                glorifies violence.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content depicting violent or graphic events involving humans
                    or animals.
                  </li>
                </ul>
                Suicide, Self-Harm, and Dangerous Acts <br /> We prioritize the
                health and well-being of our community members. We do not allow
                content that could lead to suicide, self-harm, eating disorders,
                or dangerous activities.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content related to suicide, self-harm, eating disorders, or
                    dangerous acts.
                  </li>
                </ul>
                Harassment and Bullying <br />
                We strive for an inclusive community free from harassment and
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Abusive Behavior</h1>
              <p>
                We remove expressions of abuse, including threats or derogatory
                statements.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that insults, intimidates, or harasses individuals.
                  </li>
                </ul>
                Sexual Harassment <br /> Sexual harassment in any form is not
                tolerated.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that includes unwanted sexual behavior or
                    commentary.
                  </li>
                </ul>
                Threats of Hacking, Doxxing, and Blackmail <br />
                We consider online behaviors like doxxing and hacking as forms
                of abuse.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Threats to reveal personal data or engage in hacking or
                    blackmail.
                  </li>
                </ul>
                Adult Nudity and Sexual <br /> Activitie We prohibit pornography
                and sexually explicit content
              </p>
            </div>
            <div className="text_div">
              <h1>Sexual Exploitation</h1>
              <p>Sexually exploitative content is not permitted.</p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content depicting non-consensual sexual acts or nudity. <br />
                Integrity and Authenticity <br />
                We maintain the authenticity of our platform and prevent
                activities undermining its integrity.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam and Fake Engagement</h1>
              <p>We prohibit artificial manipulation of engagement metrics.</p>
            </div>
            <div className="text_div">
              <h1>Impersonation</h1>
              <p>
                We do not allow deceptive impersonation but permit parody or
                commentary accounts if clearly indicated.
              </p>
            </div>
            <div className="text_div">
              <h1>Misinformation</h1>
              <p>We do not permit harmful misinformation.</p>
            </div>
            <div className="text_div">
              <h1>Intellectual Property Violations</h1>
              <p>
                We respect intellectual property rights and remove content that
                infringes these rights. <br />
                General Commitments <br />
                <ul>
                  <li>
                    <h1>Safety:</h1>Commitment to making TurkAfrica.Market a
                    safe place.
                  </li>
                  <li>
                    <h1>Privacy:</h1>Protecting personal privacy and
                    information.
                  </li>
                  <li>
                    <h1>Dignity:</h1>Respecting the dignity and rights of all
                    people.
                  </li>
                </ul>
                These guidelines apply globally to all content and are designed
                to be comprehensive. We understand that context matters and
                strive to apply our policies consistently and fairly. Users can
                report violations, and we may notify law enforcement in cases of
                genuine risk.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
