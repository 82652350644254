import React from "react";
import "./Newsection2.css";
import sec6img from "../../../../assets/why_wait.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const Index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div">
        <div className="Newsection2_text_div">
          <h1>Kwanini Usubiri?</h1>
          <p>
            <span>Gundua Masoko Mapya:</span> Pata ufikiaji wa mtandao mkubwa wa
            biashara na wateja barani Afrika na Türkiye.
          </p>
          <p>
            <span>Fanya Ushirikiano wa Kimkakati:</span>
            Unganisha moja kwa moja na washirika wanaoweza kuwa, wawekezaji na
            vyama vya biashara.
          </p>
          <p>
            <span>Zungumza Bure:</span> Anzisha mazungumzo mara moja na biashara
            zinazoshiriki maono yako—bila gharama.
          </p>
          <p>
            Pakua App ya B2B ya TurkAfrica <span>BILA MALIPO</span> leo, na
            chukua hatua ya kwanza kuelekea upanuzi wa biashara yako kimataifa.
            Hatua ya baadaye ya biashara yako iko karibu tu na kugusa!
          </p>
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Usikose—Jiunge Nasi Sasa!
          </Link>
        </div>
        <div className="Newsection2_img_div">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
