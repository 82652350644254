import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img2, name: "Madencilik Şirketi" },
    { id: 2, img: img3, name: "Yatırımcı" },
    { id: 3, img: img4, name: "Kahve Üreticisi" },
    { id: 4, img: img9, name: "Kahve İthalatçısı" },
    { id: 5, img: img8, name: "Perakendeci" },
    { id: 6, img: img11, name: "Güzellik Kliniği" },
    { id: 7, img: img12, name: "Tarım İşletmesi" },
    { id: 8, img: img7, name: "Otomotiv Yedek Parçaları" },
    { id: 9, img: img10, name: "İç ve Dış Tasarım Şirketi" },
    { id: 10, img: img6, name: "Tekstil İmalatı Toptancısı" },
    { id: 11, img: img5, name: "İş Hukukçusu/ Kurumsal Avukat" },
    { id: 12, img: img1, name: "Dekorasyon ve Mobilya Üreticisi" },
  ];
  return (
    <div className="Section3" id="Aboutus">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Hakkımızda</h1>
          <p>
            <span>TurkAfrica B2B Uygulaması</span> ile eşi görülmemiş iş
            fırsatlarını keşfedin!
          </p>
          <p>
            Afrika veya Türkiye'de girişimci ya da iş sahibi misiniz? Hayal
            edin,{" "}
            <span>52 milyon KOBİ Afrika'da ve 3,2 milyon KOBİ Türkiye'de,</span>
            tümü ufkunu genişletmek ve yeni pazarlar keşfetmek istiyor.
            <span>1,5 milyar tüketici</span> hizmet bekliyor, büyüme potansiyeli
            sınırsız.
          </p>
          <p>
            <span>TurkAfrica B2B Uygulaması'na hoş geldiniz — </span>fırsatlar
            dünyasına kapılarınızı açın! Dağıtıcı, üretici, ihracatçı,
            ithalatçı, yatırımcı, lisans veren, lisans alan, toptancı ya da
            perakendeci olun, TurkAfrica B2B Uygulaması, sınırları aşan güçlü
            ortaklıklar kurmanıza yardımcı olmak için tasarlandı.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
