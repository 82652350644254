import React from "react";
import "./Newsection2.css";
import sec6img from "../../../../assets/why_wait.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const Index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div">
        <div className="Newsection2_text_div">
          <h1>¿Por Qué Esperar?</h1>
          <p>
            <span>Descubra Nuevos Mercados:</span> Acceda a una vasta red de
            negocios y consumidores en toda África y Turquía.
          </p>
          <p>
            <span>Forje Alianzas Estratégicas:</span>
            Conéctese directamente con socios potenciales, inversores y
            asociaciones empresariales.
          </p>
          <p>
            <span>Chatee Gratis:</span>
            Comience conversaciones instantáneas con empresas que comparten su
            visión—sin costo.
          </p>
          <p>
            Descargue la App B2B TurkAfrica <span>GRATIS</span> hoy, y dé el
            primer paso hacia la expansión global de su negocio. ¡El futuro de
            su negocio está a solo un toque de distancia!
          </p>
          <Link className="PRE_btn" to="Contact" offset={-100}>
            ¡No Se Quede Fuera—Únase a Nosotros Ahora!
          </Link>
        </div>
        <div className="Newsection2_img_div">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
