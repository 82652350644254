import React from "react";
import "./Newsection.css";
import sec6img from "../../../../assets/Newsection.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";

const Index = () => {
  return (
    <div className="Newsection" id="Investors">
      <div className="submain_Newsection_div">
        <div className="Newsection_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection_text_div">
          <h1>
            Participação Acionária e <br />
            Oportunidades de Investimento
          </h1>
          <p>
            Caso tenha interesse em explorar oportunidades para se tornar um
            acionista valorizado ou deseje investir na <b>TurkAfrica</b>,
            convidamo-lo calorosamente para entrar em contato conosco. Para mais
            detalhes e para iniciar uma conversa, por favor, dirija-se à seção
            'Contato'. Aguardamos ansiosamente a oportunidade de conectar-nos
            com você.
          </p>
          <div className="Newsection_store_div">
            <p>Aplicativo Em Breve</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
