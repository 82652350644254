import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img2, name: "Compagnie minière" },
    { id: 2, img: img3, name: "Investisseur" },
    { id: 3, img: img4, name: "Producteur de café" },
    { id: 4, img: img9, name: "Importateur de café" },
    { id: 5, img: img8, name: "Détaillant" },
    { id: 6, img: img11, name: "Clinique de beauté" },
    { id: 7, img: img12, name: "Entreprise agricole" },
    { id: 8, img: img7, name: "Pièces détachées automobiles" },
    { id: 9, img: img10, name: "Entreprise de design intérieur et extérieur" },
    { id: 10, img: img6, name: "Grossiste en fabrication textile”" },
    { id: 11, img: img5, name: "Avocat d'affaires / Avocat d’entreprise" },
    { id: 12, img: img1, name: "Fabricant de décoration et de meubles" },
  ];
  return (
    <div className="Section3" id="Aboutus">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>À Propos De Nous</h1>
          <p>
            Libérez des opportunités commerciales inédites avec l'
            <span>application B2B TurkAfrica!</span>
          </p>
          <p>
            Êtes-vous un entrepreneur ou un propriétaire d'entreprise en Afrique
            ou en Turquie? Imaginez-vous connecter avec plus de{" "}
            <span>
              52 millions de PME en Afrique et 3,2 millions de PME en Turquie,
            </span>
            tous désireux d'élargir leurs horizons et d'explorer de nouveaux
            marchés. Avec plus de
            <span>1,5 milliard de consommateurs</span> attendant d'être servis,
            le potentiel de croissance est illimité.
          </p>
          <p>
            <span>Découvrez l'application B2B TurkAfrica — </span>votre
            passerelle vers un monde de possibilités! Que vous soyez
            distributeur, fabricant, exportateur, importateur, investisseur,
            concédant de licence, licencié, grossiste ou détaillant,
            l'application B2B TurkAfrica est conçue pour vous aider à établir
            des réseaux, conclure des accords et former des partenariats
            puissants qui transcendent les frontières.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
